import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    paddingTop: 2,
  },
  container: {
    height: 'calc(100vh - 162px)',
  },
}))
