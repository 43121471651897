import { useStyles } from './styles'
import { useEffect, useState } from 'react'
import { getUser } from '../../services/User/getUser'

export default function UserHook() {
  const classes = useStyles()
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const columns = [
    { id: 'id', label: 'ID' },
    { id: 'name', label: 'ชื่อ-นามสกุล' },
  ]

  useEffect(() => {
    getUser().then(({ data }) => {
      if (data.data) {
        const result = []
        data.data.forEach((item, index) => {
          const mixData = { ...item.attributes, id: index + 1 }
          result.push(mixData)
        })
        setRows(result.reverse())
      }
    })
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return { classes, columns, rows, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage }
}
