import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  socialWrap: {
    margin: theme.spacing(2, 3),
    [theme.breakpoints.up('350')]: {
      margin: theme.spacing(2, 4),
    },
    [theme.breakpoints.up('400')]: {
      margin: theme.spacing(2, 5),
    },
    [theme.breakpoints.up('500')]: {
      margin: theme.spacing(2, 6),
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(2, 7),
    },
    textAlign: 'center',
  },
  socialLogo: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  input: {
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))
