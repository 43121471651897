import { Grid } from '@material-ui/core'
import { useStyles } from './styles'
import './sakura.scss'
import './text-animation.scss'
import HeaderHook from './Header.hook'

export default function Header() {
  const classes = useStyles()
  const { title, hashtag } = HeaderHook()

  return (
    <>
      <Grid container justifyContent="center" className={[classes.dateHead, 'ab-c-t'].join(' ')}>
        <div className="snow-bg"></div>
        <div className="sign">
          {title?.map((item, index) => (
            <span className={`flicker${index}`}>{item}</span>
          ))}
        </div>
      </Grid>
      <Grid container justifyContent="center" className={[classes.dateHead, 'ab-c-t'].join(' ')}>
        <div className="sign bottom">{hashtag}</div>
      </Grid>
    </>
  )
}
