import { useRecoilValue, useSetRecoilState } from 'recoil'
import configAppAtom from '../../../recoil/configApp'
import dialogAtom from '../../../recoil/dialog'
import {
  BeachAccess,
  EmojiPeopleOutlined,
  FavoriteBorderOutlined,
  FeaturedPlayListOutlined,
  FiberSmartRecordOutlined,
  LibraryMusicOutlined,
  LocalFloristOutlined,
  MeetingRoomOutlined,
  MenuBookOutlined,
  MusicNoteOutlined,
  PeopleAltOutlined,
  PersonOutlineOutlined,
  QueueMusicOutlined,
  RecordVoiceOverOutlined,
  WcOutlined,
  WhatshotOutlined,
} from '@material-ui/icons'
import { useStyles } from './styles'

export default function CeremonyHook() {
  const classes = useStyles()
  const configApp = useRecoilValue(configAppAtom)
  const setDialog = useSetRecoilState(dialogAtom)
  const ceremony = configApp.ceremony
  const componentsIcon = {
    BeachAccess: BeachAccess,
    MusicNoteOutlined: MusicNoteOutlined,
    QueueMusicOutlined: QueueMusicOutlined,
    WhatshotOutlined: WhatshotOutlined,
    EmojiPeopleOutlined: EmojiPeopleOutlined,
    WcOutlined: WcOutlined,
    FavoriteBorderOutlined: FavoriteBorderOutlined,
    LocalFloristOutlined: LocalFloristOutlined,
    MenuBookOutlined: MenuBookOutlined,
    FiberSmartRecordOutlined: FiberSmartRecordOutlined,
    RecordVoiceOverOutlined: RecordVoiceOverOutlined,
    PersonOutlineOutlined: PersonOutlineOutlined,
    LibraryMusicOutlined: LibraryMusicOutlined,
    PeopleAltOutlined: PeopleAltOutlined,
    FeaturedPlayListOutlined: FeaturedPlayListOutlined,
    MeetingRoomOutlined: MeetingRoomOutlined,
  }

  return { classes, ceremony, componentsIcon, setDialog }
}
