import { makeStyles, withStyles } from '@material-ui/core'
import muiListItemText from '@material-ui/core/ListItemText'

export const useStyles = makeStyles((theme) => ({
  newsList: {
    marginBottom: theme.spacing(1),
  },
}))

export const ListItemText = withStyles((theme) => ({
  primary: {
    fontSize: 13,
    lineHeight: 1,
  },
  secondary: {
    marginTop: theme.spacing(0.5),
  },
}))(muiListItemText)
