import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  wrapperComment: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  nocomment: {
    textAlign: 'center',
    paddingTop: 20,
    paddingBottom: 20,
  },
  commentIcon: {
    fontSize: 180,
    color: '#eeeeee',
  },
  comment: {
    backgroundColor: '#f5f5f5',
    borderRadius: theme.spacing(2),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  list: {
    marginBottom: theme.spacing(1),
  },
  delete: {
    margin: theme.spacing(1),
    backgroundColor: '#dc3545',
  },
}))
