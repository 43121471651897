import {
  Typography,
  Grid,
  Card,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Collapse,
  TextField,
} from '@material-ui/core'
import {
  ArrowForwardIosOutlined,
  SpellcheckOutlined,
  EmailOutlined,
  LinkOutlined,
  VerifiedUserOutlined,
  PowerSettingsNewOutlined,
  DoneOutlined,
  Close,
} from '@material-ui/icons'
import Alert from '@material-ui/lab/Alert'
import FullScreenDialog from '../Dialog'
import Payment from '../Payment'
import AccountHook from './Account.hook'
import GetVersion from '../../lib/utils/getVersion'
import moment from 'moment'

export default function Account({ handleAuth }) {
  const { version } = GetVersion()
  const {
    logo,
    classes,
    alert,
    setAlert,
    change,
    value,
    setValue,
    fullscreenDialog,
    profile,
    configApp,
    handleClose,
    CopyToClipboard,
    handlePayment,
    ChangeName,
    handleChangeName,
  } = AccountHook()

  return (
    <>
      <Grid
        container
        item
        xs={12}
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.profileWrapper}
      >
        <Avatar alt={profile?.name} src={profile?.avatar} className={classes.avatar} />
        <div className="pt-3 pb-1">
          {change ? (
            <div className="d-flex align-items-end bg-white p-3 rounded">
              <TextField
                id="name"
                placeholder="ชื่อ-นามสกุล"
                name="name"
                autoFocus
                autoComplete="off"
                size="small"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <div className="ml-1">
                <IconButton size="small" onClick={handleChangeName}>
                  <Avatar className={classes.icon}>
                    <DoneOutlined fontSize="small" />
                  </Avatar>
                </IconButton>
              </div>
            </div>
          ) : (
            <Typography noWrap variant="h5">
              <strong>{profile?.name}</strong>
            </Typography>
          )}
        </div>
        <Typography noWrap>{profile?.email}</Typography>
      </Grid>
      <Collapse in={alert}>
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setAlert(false)}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          คัดลอกสำเร็จ
        </Alert>
      </Collapse>
      <Typography variant="subtitle2" color="textSecondary" className="p-3">
        MEMBERSHIP CARD
      </Typography>
      <Grid container justifyContent="center" className={classes.cardWrap}>
        <Card className={[classes.card, 'bg-card p-4'].join(' ')}>
          <div className="d-flex justify-content-between align-items-center pb-1">
            <img
              className={[classes.logo, 'img-fluid'].join(' ')}
              src={logo}
              width="80"
              alt="logo"
            />
            <div className="d-flex flex-column">
              <Typography variant="body2" noWrap align="right" className="text-gd pb-1">
                ยินดีต้อนรับ
              </Typography>
              <Typography variant="h5" noWrap align="right" className="text-gd-invert">
                แขกผู้มีเกียรติ
              </Typography>
            </div>
          </div>
          <Grid container item xs={12} justifyContent="center" className="py-3">
            <Typography noWrap variant="h6" className="text-gd">
              4 5 3 5&emsp;&nbsp;&nbsp;5 3 7 7&emsp;&nbsp;&nbsp;3 3 5 3&emsp;&nbsp;&nbsp;1 3 7 8
            </Typography>
          </Grid>
          <Grid container item xs={12} direction="column" justifyContent="center">
            <Typography noWrap className="text-gd">
              PREMIUM CARD
            </Typography>
          </Grid>
          <Grid container justifyContent="space-between" className="pt-1 pb-3">
            <Grid item xs={6}>
              <Typography variant="body2" className="text-gd text-upper">
                {profile?.name ? `K. ${profile?.name}` : configApp?.title}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" className="text-gd-invert text-right">
                EXP. {moment(configApp?.start_date).format('MM/YY')}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <List className={classes.list}>
        {profile?.username && (
          <>
            <Divider light />
            <ListItem button className={classes.listItem} onClick={ChangeName}>
              <Avatar className={classes.icon}>
                <SpellcheckOutlined fontSize="small" />
              </Avatar>
              <ListItemText primary="แก้ไขชื่อ-นามสกุล" className={classes.listText} />
              <ArrowForwardIosOutlined />
            </ListItem>
          </>
        )}
        <Divider light />
        <ListItem button className={classes.listItem} onClick={handlePayment}>
          <Avatar className={classes.icon}>
            <EmailOutlined />
          </Avatar>
          <ListItemText primary="กล่องรับซอง" className={classes.listText} />
          <ArrowForwardIosOutlined />
        </ListItem>
        <Divider light />
        <ListItem
          button
          className={classes.listItem}
          onClick={() => CopyToClipboard(process.env.REACT_APP_URL)}
        >
          <Avatar className={classes.icon}>
            <LinkOutlined />
          </Avatar>
          <ListItemText primary="คัดลอกลิงค์แอพพลิเคชั่น" className={classes.listText} />
          <Typography>คัดลอก</Typography>
        </ListItem>
        <Divider light />
        <ListItem button className={classes.listItem}>
          <Avatar className={classes.icon}>
            <VerifiedUserOutlined />
          </Avatar>
          <ListItemText primary="เวอร์ชั่น" className={classes.listText} />
          <Typography>{version}</Typography>
        </ListItem>
        <Divider light />
        <ListItem button className={classes.listItem} onClick={handleAuth}>
          <Avatar className={classes.icon}>
            <PowerSettingsNewOutlined />
          </Avatar>
          <ListItemText
            primary={profile?.username ? `ออกจากระบบ` : 'เข้าสู่ระบบ'}
            className={classes.listText}
          />
          <ArrowForwardIosOutlined />
        </ListItem>
        <Divider light />
      </List>

      <FullScreenDialog handleCloseDialog={handleClose} open={fullscreenDialog} title="กล่องรับซอง">
        <Payment />
      </FullScreenDialog>
    </>
  )
}
