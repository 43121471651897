import { isEmpty } from 'lodash'
import { useStyles } from './styles'
import { useEffect, useState } from 'react'
import qs from 'qs'
import { createUser } from '../../services/User/createUser'
import { findUser } from '../../services/User/findUser'
import { updateUser } from '../../services/User/updateUser'
import { useRecoilState, useSetRecoilState } from 'recoil'
import profileAtom from '../../recoil/profile'
import configAppAtom from '../../recoil/configApp'
import { getApp } from '../../services/Config/getApp'

export default function LoginHook({ location, history }) {
  const classes = useStyles()
  const [configApp, setConfigApp] = useRecoilState(configAppAtom)
  const setProfile = useSetRecoilState(profileAtom)
  const [loading, setLoading] = useState(false)
  const [register, setRegister] = useState({ name: '', username: '' })
  const [gender, setGender] = useState('male')
  const background =
    configApp.cover &&
    process.env.REACT_APP_API_URL + configApp.cover?.data.attributes.formats.large.url
  const logo = configApp.logo && process.env.REACT_APP_API_URL + configApp.logo?.data.attributes.url

  const search = qs.parse(location.search)
  if (!isEmpty(search.table)) {
    localStorage.setItem('table', search.table)
    history.push('/')
  }

  const responseFacebook = async (response) => {
    const data = {
      name: response.name,
      avatar: response.picture?.data?.url,
      username: response.email,
    }
    localStorage.clear()
    localStorage.setItem('username', response.email)
    await addUser(data)
    history.push('/')
  }

  const responseGoogle = async (response) => {
    const data = {
      name: response.name,
      avatar: response.picture,
      username: response.email,
    }
    localStorage.clear()
    localStorage.setItem('username', response.email)
    await addUser(data)
    history.push('/')
  }

  const addUser = async (params) => {
    return await findUser(params)
      .then(async ({ data }) => {
        if (isEmpty(data.data)) {
          await createUser(params).then(({ data }) => {
            const id = data.data.id
            setProfile({ ...data.data.attributes, id })
          })
        } else {
          const [res] = data.data
          const updateID = res.id
          await updateUser(updateID, params).then(({ data }) => {
            const id = data.data.id
            setProfile({ ...data.data.attributes, id })
          })
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    const data = {
      name: register.name,
      avatar: `${process.env.REACT_APP_URL}/assets/images/${gender}.svg`,
      username: register.username,
    }
    localStorage.clear()
    localStorage.setItem('username', register.username)
    await addUser(data)
    history.push('/')
  }

  useEffect(() => {
    getApp().then((res) => {
      setConfigApp(res.data.data?.attributes || {})
    })
  }, [])

  return {
    classes,
    loading,
    setLoading,
    gender,
    register,
    setRegister,
    setGender,
    responseFacebook,
    responseGoogle,
    onSubmit,
    background,
    logo,
  }
}
