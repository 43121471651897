import { createTheme } from '@material-ui/core'

export const THEME = createTheme({
  shadows: ['none'],
  typography: {
    fontFamily: '"Kanit", "Roboto"',
  },
  palette: {
    primary: {
      main: '#B1C6C1',
    },
    secondary: {
      main: '#D4BAB0',
    },
  },
  overrides: {
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: '#B1C6C1',
      },
    },
  },
})
