import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 56,
    paddingRight: 5,
  },
  title: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
  },
  container: {
    marginTop: 56,
    marginBottom: 51,
  },
  button: {
    position: 'fixed',
    bottom: theme.spacing(6),
    marginRight: theme.spacing(2),
    zIndex: 1600,
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.primary.main,
  },
  fileuploadWrapper: {
    padding: theme.spacing(2),
    marginTop: 56,
    marginBottom: 51,
  },
  uploadBox: {
    textAlign: 'center',
  },
  dropbox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '100px 0px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginBottom: theme.spacing(2),
  },
  imageUpload: {
    maxWidth: '100%',
    maxHeight: 400,
    marginBottom: theme.spacing(2),
  },
  share: {
    fontSize: 18,
  },
}))
