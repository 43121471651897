import { Avatar, Card, CardContent, Divider, Grid, IconButton, Typography } from '@material-ui/core'
import { useStyles } from './styles'
import { AlarmOutlined, BusinessOutlined, WeekendOutlined } from '@material-ui/icons'
import { isEmpty } from 'lodash'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import configAppAtom from '../../../../recoil/configApp'
import moment from 'moment'
import dialogAtom from '../../../../recoil/dialog'
import profileAtom from '../../../../recoil/profile'

export default function Action({ handleChange }) {
  const classes = useStyles()
  const configApp = useRecoilValue(configAppAtom)
  const profile = useRecoilValue(profileAtom)
  const setDialog = useSetRecoilState(dialogAtom)

  return (
    <div className={classes.cardWrapper}>
      <Card>
        <CardContent>
          <Typography gutterBottom>
            {<strong> {!isEmpty(profile) ? `คุณ ${profile.name || ''}` : 'ยินดีต้อนรับ'} </strong>}
          </Typography>
          <Divider light className="mt-3 mb-1" />
          <Grid container spacing={1}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              item
              xs={4}
              onClick={() => {
                if (!isEmpty(profile)) {
                  setDialog('Account')
                } else {
                  window.location.href = '/login'
                }
              }}
            >
              <div className="text-center w-100">
                <IconButton>
                  <Avatar
                    className={[classes.icon, 'animate__animated animate__pulse animate__infinite']}
                  >
                    <WeekendOutlined />
                  </Avatar>
                </IconButton>
              </div>
              <Typography align="center" variant="body2">
                แขกผู้มีเกียรติ
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              item
              xs={4}
              onClick={(e) => handleChange(e, 3)}
            >
              <div className="text-center w-100">
                <IconButton>
                  <Avatar
                    className={[classes.icon, 'animate__animated animate__pulse animate__infinite']}
                  >
                    <BusinessOutlined />
                  </Avatar>
                </IconButton>
              </div>
              <Typography align="center" variant="body2">
                {configApp?.map?.length > 0 && configApp.map[0].title}
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              item
              xs={4}
              onClick={() => setDialog('Calendar')}
            >
              <div className="text-center w-100">
                <IconButton>
                  <Avatar
                    className={[classes.icon, 'animate__animated animate__pulse animate__infinite']}
                  >
                    <AlarmOutlined />
                  </Avatar>
                </IconButton>
              </div>
              <Typography align="center" variant="body2">
                {configApp.start_date &&
                  configApp.end_date &&
                  `${moment(configApp.start_date).format('HH.mm')}-
                ${moment(configApp.end_date).format('HH.mm')}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}
