import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import moment from 'moment'
import { LoadingNews } from '../Loading'
import { Fragment } from 'react'
import { ListItemText } from './styles'
import UserPost from './components/UserPost'
import { isEmpty } from 'lodash'
import NewsHook from './News.hook'
import { IconButton, ListItemSecondaryAction, Menu, MenuItem } from '@material-ui/core'
import { DeleteOutlineOutlined, MoreVertOutlined } from '@material-ui/icons'

export default function News() {
  const { classes, news, profile, anchorEl, handleClick, setAnchorEl, onDelete } = NewsHook()

  return (
    <div>
      {isEmpty(news) ? (
        <LoadingNews />
      ) : (
        <List className={classes.newsList}>
          <ListSubheader>กิจกรรมของฉัน</ListSubheader>
          {news?.map((item, index) => (
            <Fragment key={index}>
              <ListItem>
                <UserPost username={item.username} />
                <ListItemText primary={item.title} secondary={moment(item.time).from()} />
                {profile.username === process.env.REACT_APP_ADMIN && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(e) => handleClick(e, item)}
                    >
                      <MoreVertOutlined />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            </Fragment>
          ))}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={onDelete}>
              <DeleteOutlineOutlined className="mr-3" />
              ลบรายการนี้
            </MenuItem>
          </Menu>
        </List>
      )}
    </div>
  )
}
