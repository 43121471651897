import axios from 'axios'

export const createUser = async (params) => {
  const config = {
    headers: {
      Authorization: 'Bearer ' + process.env.REACT_APP_API_TOKEN,
    },
  }

  return await axios.post(
    `${process.env.REACT_APP_API_URL}/api/wedding-users`,
    { data: params },
    config,
  )
}
