import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  gallery: {
    height: '40vh',
    marginTop: 35,
    padding: theme.spacing(2, 1),
  },
  border: {
    border: '4px solid #FFF',
    color: theme.palette.common.white,
  },
}))
