import React, { useEffect, useState } from 'react'
import './style.scss'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import Container from '@material-ui/core/Container'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import Button from '@material-ui/core/Button'
import shuffle from 'shuffle-array'
import { LoadingGallery } from '../../components/Loading'
import { useStyles } from './styles'
import { useRecoilValue } from 'recoil'
import configAppAtom from '../../recoil/configApp'

export default function Gallery(props) {
  const classes = useStyles()
  const configApp = useRecoilValue(configAppAtom)
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [gallery, setGallery] = useState([])
  const path = process.env.REACT_APP_API_URL

  useEffect(() => {
    window.scrollTo(0, 0)
    if (configApp.gallery) {
      const data = []
      configApp.gallery.data.map((items) => {
        return data.push(items.attributes)
      })
      setGallery(shuffle(data))
    }
  }, [configApp])

  const handleOpenPopup = (pos) => {
    setIsOpen(true)
    setPhotoIndex(pos)
  }

  const handleClosePopup = () => {
    setIsOpen(false)
  }

  const { handleChange } = props

  return (
    <div className={classes.galleryWrap}>
      <Container maxWidth="md" disableGutters>
        <div className={classes.gridList}>
          {!gallery ? (
            <LoadingGallery />
          ) : (
            <GridList cellHeight={160} cols={3}>
              {gallery.map((items, index) => (
                <GridListTile key={index} cols={1} onClick={() => handleOpenPopup(index)}>
                  <img src={path + items.formats.small.url} alt={items.alternativeText} />
                </GridListTile>
              ))}
            </GridList>
          )}
        </div>
        <div className={classes.buttonWrap} onClick={(e) => handleChange(e, 0)}>
          <Button fullWidth variant="contained" color="primary" size="large">
            back to home
          </Button>
        </div>
      </Container>
      {isOpen && (
        <Lightbox
          mainSrc={path + gallery[photoIndex].url}
          nextSrc={path + gallery[(photoIndex + 1) % gallery.length].url}
          prevSrc={path + gallery[(photoIndex + gallery.length - 1) % gallery.length].url}
          onCloseRequest={handleClosePopup}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
          }
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % gallery.length)}
        />
      )}
    </div>
  )
}
