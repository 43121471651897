import axios from 'axios'
import qs from 'qs'

export const findUser = async ({ username }) => {
  const config = {
    headers: {
      Authorization: 'Bearer ' + process.env.REACT_APP_API_TOKEN,
    },
  }

  const query = qs.stringify({
    filters: {
      username: {
        $eq: username,
      },
    },
  })

  return await axios.get(`${process.env.REACT_APP_API_URL}/api/wedding-users?${query}`, config)
}
