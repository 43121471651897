import { useEffect, useState } from 'react'
import {
  Avatar,
  ListItem,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import _, { isEmpty } from 'lodash'
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import SendOutlinedIcon from '@material-ui/icons/SendOutlined'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { db } from '../../firebase'
import * as firebase from 'firebase/app'
import moment from 'moment'
import { LoadingComment } from '../Loading'
import { useStyles } from './styles'
import UserPost from './components/UserPost'
import { useRecoilValue } from 'recoil'
import profileAtom from '../../recoil/profile'

export default function Comment(props) {
  const classes = useStyles()
  const profile = useRecoilValue(profileAtom)
  const { IDComment } = props
  const [values, setValues] = useState('')
  const [loading, setLoading] = useState(true)
  const [comment, setComment] = useState([])
  const [removeItem, setRemoveItem] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [popup, setPopup] = useState(false)

  useEffect(() => {
    const getComment = () => {
      db.collection(process.env.REACT_APP_PROJECT_ID)
        .doc('bless')
        .collection('data')
        .doc(IDComment)
        .onSnapshot((doc) => {
          if (doc.exists) {
            let newArray = []
            doc.data().comment.forEach((element) => {
              newArray.push(JSON.parse(element))
            })
            setLoading(false)
            setComment(newArray)
            setValues('')
          }
        })
    }
    getComment()
    return () => {
      getComment()
    }
  }, [IDComment])

  const handleClick = (event, items) => {
    setRemoveItem(items)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setPopup(false)
  }

  const handleOpenPopup = () => {
    setPopup(true)
    setAnchorEl(null)
  }

  const handleChange = (e) => {
    setValues(e.target.value)
  }

  const handleSubmit = () => {
    let newData = {
      username: profile.username,
      comment: values,
      commentID: moment().unix(),
    }
    const docRef = db
      .collection(process.env.REACT_APP_PROJECT_ID)
      .doc('bless')
      .collection('data')
      .doc(IDComment)
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          db.collection(process.env.REACT_APP_PROJECT_ID)
            .doc('bless')
            .collection('data')
            .doc(IDComment)
            .set(
              {
                comment: [...doc.data().comment, JSON.stringify(newData)],
              },
              { merge: true },
            )
        } else {
          console.log('No such document!')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onDelete = () => {
    const value = JSON.stringify(removeItem)
    const blessRef = db
      .collection(process.env.REACT_APP_PROJECT_ID)
      .doc('bless')
      .collection('data')
      .doc(IDComment)

    blessRef
      .update({
        comment: firebase.firestore.FieldValue.arrayRemove(value),
      })
      .then((response) => {
        handleClose()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div>
      {loading ? (
        <LoadingComment />
      ) : (
        <div>
          <Typography variant="subtitle2" color="textSecondary" className="p-3">
            แสดงความคิดเห็น
          </Typography>
          {!_.isEmpty(comment) ? (
            comment.map((items, index) => (
              <div key={index} className={classes.wrapperComment}>
                <ListItem className={classes.comment}>
                  <UserPost {...items} />
                  {profile.username === items.username && (
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(e) => handleClick(e, items)}
                    >
                      <MoreVertOutlinedIcon />
                    </IconButton>
                  )}
                </ListItem>
              </div>
            ))
          ) : (
            <div className={classes.nocomment}>
              <QuestionAnswerIcon className={classes.commentIcon} />
              <Typography color="textSecondary" className="pb-2">
                <strong>ยังไม่มีความคิดเห็น</strong>
              </Typography>
              <Typography color="textSecondary">เป็นคนแรกที่แสดงความคิดเห็น</Typography>
            </div>
          )}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleOpenPopup}>
              <DeleteOutlineOutlinedIcon className="mr-3" />
              ลบความคิดเห็นนี้
            </MenuItem>
          </Menu>

          {!isEmpty(profile) && (
            <ValidatorForm
              onSubmit={handleSubmit}
              onError={(errors) => console.log(errors)}
              className="px-4 pt-2 pb-4 mb-5"
            >
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <TextValidator
                    fullWidth
                    label="เขียนความคิดเห็น"
                    name="comment"
                    variant="outlined"
                    autoComplete="off"
                    onChange={handleChange}
                    value={values}
                    validators={['required']}
                    errorMessages={['กรุณาเขียนแสดงความคิดเห็น']}
                  />
                </Grid>
                <Grid item xs={2} className="text-right">
                  <IconButton type="submit">
                    <Avatar className={classes.icon}>
                      <SendOutlinedIcon />
                    </Avatar>
                  </IconButton>
                </Grid>
              </Grid>
            </ValidatorForm>
          )}

          <Dialog
            open={popup}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth
            maxWidth="sm"
            aria-describedby="alert-dialog-description"
          >
            <div className={classes.paper}>
              <Avatar className={classes.delete}>
                <DeleteOutlineOutlinedIcon />
              </Avatar>
              <Typography variant="h5">Delete</Typography>
            </div>
            <DialogContent>
              <DialogContentText className="text-center" id="alert-dialog-description">
                คุณต้องการลบความคิดเห็นนี้ใช่หรือไม่
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>ยกเลิก</Button>
              <Button onClick={onDelete} autoFocus>
                ยืนยัน
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  )
}
