import { Typography, Divider, Grid } from '@material-ui/core'
import { useStyles } from './styles'
import { useRecoilValue } from 'recoil'
import dialogAtom from '../../../recoil/dialog'

export default function CeremonyAction() {
  const classes = useStyles()
  const dialog = useRecoilValue(dialogAtom)

  return (
    <div className={classes.root}>
      <Typography color="textSecondary" component="div">
        {dialog?.map((item, index) => {
          const { title, description } = item

          return (
            <>
              <Grid container justifyContent="space-around" alignItems="center">
                <Grid item xs={3}>
                  <Typography align="center" color="primary" variant="h2">
                    {title}
                  </Typography>
                </Grid>
                <Grid item xs={9} className={classes.description}>
                  <Typography
                    color="textSecondary"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </Grid>
              </Grid>
              <Divider light className={classes.divider} />
            </>
          )
        })}
      </Typography>
    </div>
  )
}
