import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  profileWrapper: {
    padding: theme.spacing(0, 2, 4, 2),
    backgroundColor: theme.palette.primary.main,
  },
  cardWrap: {
    padding: theme.spacing(0, 1, 2, 1),
  },
  card: {
    maxWidth: 400,
    borderRadius: 15,
  },
  logo: {
    filter: 'invert(1)',
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    border: '3px solid #FFF',
  },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
  },
  list: {
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    padding: '11px 20px',
  },
  listText: {
    paddingLeft: theme.spacing(3),
  },
  iconList: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}))
