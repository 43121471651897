import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'animate.css'
import './styles/util.scss'
import './styles/main.scss'
import 'moment/locale/th'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Layout from './layout'
import Login from './pages/Login'
import QRcode from './pages/QRcode'
import Users from './pages/Users'
import NotFound from './pages/Notfound'
import { Snackbar, Fade, Button, ThemeProvider } from '@material-ui/core'
import { RecoilRoot } from 'recoil'
import { THEME } from './theme'
import { useAddToHomescreenPrompt } from './lib/hooks/useAddToHomescreenPrompt'

export default function App() {
  const { promptToInstall, isVisible, hide } = useAddToHomescreenPrompt()

  return (
    <RecoilRoot>
      <ThemeProvider theme={THEME}>
        <Snackbar
          open={isVisible}
          onClick={hide}
          style={{ zIndex: 2000, marginBottom: '55px' }}
          TransitionComponent={Fade}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message="ติดตั้งแอพนี้ ลงบนอุปกรณ์ของคุณ"
          action={
            <Button color="primary" variant="contained" onClick={promptToInstall}>
              ติดตั้ง
            </Button>
          }
        />
        <Router>
          <Switch>
            <Route exact path="/Login" component={Login} />
            <Route exact path="/" component={Layout} />
            <Route exact path="/gallery" component={Layout} />
            <Route exact path="/schedule" component={Layout} />
            <Route exact path="/tables" component={Layout} />
            <Route exact path="/maps" component={Layout} />
            <Route exact path="/bless" component={Layout} />
            <Route exact path="/qrcode" component={QRcode} />
            <Route exact path="/users" component={Users} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </ThemeProvider>
    </RecoilRoot>
  )
}
