import {
  Avatar,
  Card,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { useStyles } from './styles'
import configAppAtom from '../../../../recoil/configApp'
import { useRecoilValue } from 'recoil'

export default function Theme() {
  const classes = useStyles()
  const configApp = useRecoilValue(configAppAtom)

  return (
    <div className={classes.themeWrapper}>
      <Typography align="center" variant="h6" className={classes.headTheme}>
        Theme Colors
      </Typography>
      <Grid container alignItems="center" spacing={1}>
        {configApp?.theme?.map((item) => (
          <Grid item xs={6}>
            <Card elevation={0}>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      className={[
                        classes.avatar,
                        'animate__animated animate__pulse animate__infinite',
                      ]}
                      style={{ backgroundColor: item.color }}
                    >
                      {''}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.title_en}
                    secondary={item.title_th}
                    primaryTypographyProps={{
                      style: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                </ListItem>
              </List>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
