import Skeleton from '@material-ui/lab/Skeleton'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './styles'

const LoadingBless = () =>
  [...Array(2)].map((items, index) => {
    return (
      <div key={index} className="pb-4">
        <Grid container className="px-3">
          <Grid item xs={2}>
            <Skeleton animation="wave" variant="circle" width={40} height={40} className="mb-2" />
          </Grid>
          <Grid item xs={10}>
            <Skeleton animation="wave" variant="text" width="70%" />
            <Skeleton animation="wave" variant="text" width="30%" />
          </Grid>
        </Grid>
        <Grid container className="px-3">
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="text" width="100%" />
            <Skeleton animation="wave" variant="text" width="90%" />
          </Grid>
        </Grid>
        <Grid container className="pt-3">
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rect" width="100%" height={200} />
          </Grid>
        </Grid>
      </div>
    )
  })

const LoadingNews = () => {
  return (
    <List className="pb-2">
      <ListSubheader>แจ้งเตือนกิจกรรม</ListSubheader>
      {[...Array(8)].map((items, index) => (
        <ListItem key={index}>
          <ListItemAvatar>
            <Skeleton animation="wave" variant="circle" width={40} height={40} />
          </ListItemAvatar>
          <ListItemText
            disableTypography={true}
            primary={<Skeleton animation="wave" variant="text" width="100%" />}
            secondary={<Skeleton animation="wave" variant="text" width="30%" />}
          />
        </ListItem>
      ))}
    </List>
  )
}

const LoadingComment = () => {
  const classes = useStyles()
  return (
    <>
      <Typography variant="subtitle2" color="textSecondary" className="p-3">
        ความคิดเห็น
      </Typography>
      {[...Array(5)].map((items, index) => (
        <div key={index} className={classes.wrapperComment}>
          <ListItem className={classes.comment}>
            <ListItemAvatar>
              <Skeleton animation="wave" variant="circle" width={40} height={40} />
            </ListItemAvatar>
            <ListItemText
              disableTypography={true}
              primary={<Skeleton animation="wave" variant="text" width="100%" />}
              secondary={<Skeleton animation="wave" variant="text" width="30%" />}
            />
          </ListItem>
        </div>
      ))}
    </>
  )
}

const LoadingGallery = () => {
  return (
    <GridList cellHeight={160} cols={3}>
      {[...Array(9)].map((items, index) => (
        <GridListTile key={index} cols={1}>
          <Skeleton animation="wave" variant="rect" width={360} height={160} />
        </GridListTile>
      ))}
    </GridList>
  )
}

export { LoadingBless, LoadingNews, LoadingComment, LoadingGallery }
