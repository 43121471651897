import { ArrowBackOutlined } from '@material-ui/icons'
import {
  Dialog,
  Slide,
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  Container,
} from '@material-ui/core'
import { useStyles } from './styles'
import DialogHook from './Dialog.hook'
import { forwardRef } from 'react'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

export default function FullScreenDialog(props) {
  const classes = useStyles()
  const { logo } = DialogHook()
  const { handleCloseDialog, open, title, children } = props

  return (
    open && (
      <Dialog
        fullScreen
        open={open}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
        className={classes.dialogWrapper}
      >
        <AppBar position="fixed" className={classes.appBar}>
          <Container maxWidth="md" disableGutters>
            <Toolbar className={classes.toolbar}>
              <IconButton edge="start" color="inherit" onClick={handleCloseDialog}>
                <ArrowBackOutlined fontSize="large" />
              </IconButton>
              {title && (
                <Typography variant="h6" className={classes.title}>
                  {title}
                </Typography>
              )}

              <div>{logo && <img className="img-fluid" src={logo} width="50" alt="logo" />}</div>
            </Toolbar>
          </Container>
        </AppBar>
        <Container maxWidth="md" disableGutters>
          <div className={classes.container}>{children}</div>
        </Container>
      </Dialog>
    )
  )
}
