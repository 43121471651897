import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import './style.scss'
import 'moment/locale/th'
import { LockOutlined, NavigateNext } from '@material-ui/icons'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import LoginHook from './Login.hook'
import Copyright from './components/Copyright'
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login'
import { regex } from '../../lib/constants/regex'

export default function Login(props) {
  const {
    classes,
    loading,
    setLoading,
    gender,
    register,
    setRegister,
    setGender,
    responseFacebook,
    responseGoogle,
    onSubmit,
    background,
    logo,
  } = LoginHook(props)

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        style={{
          background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 70%, white 100%), top center / contain no-repeat url(${background})`,
        }}
      >
        <div className="bg-white-login">
          <Container maxWidth="sm" disableGutters>
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                เข้าสู่ระบบ
              </Typography>
            </div>
            <Grid container justifyContent="center">
              <LoginSocialFacebook
                appId={process.env.REACT_APP_FACEBOOK_APP_ID || ''}
                version="v16.0"
                fieldsProfile="name,email,picture.width(200)"
                redirect_uri={process.env.REACT_APP_URL}
                onLoginStart={() => setLoading(true)}
                onResolve={({ data }) => {
                  responseFacebook(data)
                }}
                onReject={(err) => {
                  console.log(err)
                  setLoading(false)
                }}
              >
                <div className={classes.socialWrap}>
                  <IconButton>
                    <Avatar
                      alt="facebook"
                      className={classes.socialLogo}
                      src="/assets/images/facebook.png"
                    />
                  </IconButton>
                  <Typography variant="body2">Login with Facebook</Typography>
                </div>
              </LoginSocialFacebook>
              <LoginSocialGoogle
                client_id={process.env.REACT_APP_GOOGLE_APP_ID || ''}
                redirect_uri={process.env.REACT_APP_URL}
                scope="openid profile email"
                discoveryDocs="claims_supported"
                access_type="offline"
                onLoginStart={() => setLoading(true)}
                onResolve={({ data }) => {
                  responseGoogle(data)
                }}
                onReject={(err) => {
                  console.log(err)
                  setLoading(false)
                }}
              >
                <div className={classes.socialWrap}>
                  <IconButton>
                    <Avatar
                      alt="google"
                      className={classes.socialLogo}
                      src="/assets/images/google.png"
                    />
                  </IconButton>
                  <Typography variant="body2">Login with Google</Typography>
                </div>
              </LoginSocialGoogle>
            </Grid>

            <div className={classes.paper}>
              <div className="line-l-r w-100 text-center my-4">
                <Typography>หรือ</Typography>
              </div>

              <ValidatorForm
                onSubmit={onSubmit}
                onError={(errors) => console.log(errors)}
                className="w-100 mb-4"
              >
                <FormLabel className="w-100">บอกให้เรารู้เกี่ยวกับตัวคุณ....</FormLabel>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    row
                  >
                    <FormControlLabel value="male" control={<Radio />} label="ชาย" />
                    <FormControlLabel value="female" control={<Radio />} label="หญิง" />
                  </RadioGroup>
                </FormControl>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextValidator
                      fullWidth
                      id="name"
                      label="ชื่อ-นามสกุล"
                      name="name"
                      autoComplete="off"
                      variant="outlined"
                      className={classes.input}
                      value={register.name}
                      onChange={(e) => setRegister({ ...register, name: e.target.value })}
                      validators={['required', `matchRegexp:${regex.fullName}`]}
                      errorMessages={['ระบุชื่อ-นามสกุล', 'ระบุชื่อ-นามสกุล (มานี วงศ์สว่าง)']}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextValidator
                      fullWidth
                      id="username"
                      label="อีเมล"
                      name="username"
                      autoComplete="off"
                      variant="outlined"
                      className={classes.input}
                      value={register.username}
                      onChange={(e) => setRegister({ ...register, username: e.target.value })}
                      validators={['required']}
                      errorMessages={['ระบุอีเมล']}
                    />
                  </Grid>
                </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  className={classes.submit}
                >
                  ลงชื่อเข้าใช้งาน <NavigateNext />
                </Button>
              </ValidatorForm>
              {logo && <img src={logo} width="80" alt="logo" />}
              <Box mt={1}>
                <Copyright />
              </Box>
            </div>
          </Container>
        </div>
      </div>
    </>
  )
}
