import Popup from './components/Popup'
import Header from './components/Header'
import Action from './components/Action'
import Gallery from './components/Gallery'
import Theme from './components/Theme'
import Youtube from './components/Youtube'
import configAppAtom from '../../recoil/configApp'
import { useRecoilValue } from 'recoil'

export default function Home({ handleChange }) {
  const configApp = useRecoilValue(configAppAtom)
  const background =
    configApp.cover &&
    process.env.REACT_APP_API_URL + configApp.cover?.data.attributes.formats.large.url

  return (
    <div>
      <div
        style={{
          background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 70%, white 100%), top center / cover no-repeat url(${background})`,
        }}
        className="bg-banner d-flex align-items-end"
      >
        <Header />
        <Action handleChange={handleChange} />
      </div>
      <Gallery />
      <Theme />
      <Youtube />
      <Popup />
    </div>
  )
}
