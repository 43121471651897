import { useRecoilValue } from 'recoil'
import { useStyles } from './styles'
import configAppAtom from '../../recoil/configApp'
import profileAtom from '../../recoil/profile'
import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

export default function TableHook() {
  const classes = useStyles()
  const configApp = useRecoilValue(configAppAtom)
  const profile = useRecoilValue(profileAtom)
  const [data, setData] = useState([])
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [searched, setSearched] = useState('')
  const [myTable, setMyTable] = useState()
  const columns = [
    { id: 'name', label: 'ชื่อ-นามสกุล', minWidth: 200 },
    { id: 'table', label: 'โต๊ะของคุณ' },
  ]

  useEffect(() => {
    if (configApp.table) {
      const data = [...configApp.table]
      data.sort((a, b) => a.name.localeCompare(b.name, ['th', 'en']))
      setRows(data)
      setData(data)
    }
  }, [configApp])

  useEffect(() => {
    if (data) {
      getTable()
    }
  }, [data])

  const requestSearch = (searchedVal) => {
    if (configApp.table) {
      const filtered = data.filter((row) => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase())
      })

      if (filtered) {
        setRows(filtered)
        setPage(0)
      }
    }
  }

  const cancelSearch = () => {
    setSearched('')
    requestSearch(searched)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getTable = () => {
    if (!isEmpty(profile)) {
      const filtered = data.filter((row) => {
        return row.name.toLowerCase().includes(profile.name.toLowerCase())
      })

      if (!isEmpty(filtered)) {
        const [result] = filtered
        setMyTable(result.table)
      }
    }
  }

  return {
    classes,
    searched,
    profile,
    myTable,
    requestSearch,
    cancelSearch,
    columns,
    rows,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  }
}
