import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
  },
  container: {
    marginTop: 56,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 56,
  },
  title: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
  },
  dialogWrapper: {
    marginBottom: 51,
  },
}))
