import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Avatar,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import SearchBar from 'material-ui-search-bar'
import { SearchOutlined, LocalDining } from '@material-ui/icons'
import TableHook from './Table.hook'
import { isEmpty } from 'lodash'

export default function MyTable() {
  const {
    classes,
    searched,
    profile,
    requestSearch,
    cancelSearch,
    columns,
    rows,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    myTable,
  } = TableHook()

  return (
    <div className={classes.root}>
      {!isEmpty(profile) && myTable && (
        <div className={classes.paperWrap}>
          <Paper className={classes.paper}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Grid container alignItems="center" justifyContent="center">
                  <LocalDining fontSize="large" className={classes.iconTable} />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">ที่นั่งของคุณคือ</Typography>
                <Grid container alignItems="center">
                  <Typography variant="h6">{myTable}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>
      )}
      <Grid container alignItems="center" className={classes.title}>
        <Avatar className={classes.icon}>
          <SearchOutlined fontSize="small" />
        </Avatar>
        <Typography className="pl-2" variant="h6">
          ค้นหาที่นั่งของคุณ
        </Typography>
      </Grid>
      <div className={classes.searchWrap}>
        <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
          placeholder="กรุณากรอกชื่อ-นามสกุล"
          className={classes.search}
        />
      </div>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id]
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100, { label: 'All', value: -1 }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
      />
    </div>
  )
}
