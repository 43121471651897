import {
  AppBar,
  Avatar,
  Badge,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Popover,
  Toolbar,
} from '@material-ui/core'
import {
  ExitToApp,
  NotificationsNoneOutlined,
  ChatOutlined,
  AccountCircleOutlined,
  AccessibilityNew,
} from '@material-ui/icons'
import { useStyles } from './styles'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import configAppAtom from '../../../recoil/configApp'
import dialogAtom from '../../../recoil/dialog'
import notificationAtom from '../../../recoil/notification'
import profileAtom from '../../../recoil/profile'

export default function Nav({ id, open, anchorEl, handleClick, handleClose, handleAuth }) {
  const classes = useStyles()
  const configApp = useRecoilValue(configAppAtom)
  const profile = useRecoilValue(profileAtom)
  const setDialog = useSetRecoilState(dialogAtom)
  const notification = useRecoilValue(notificationAtom)
  const logo = configApp.logo && process.env.REACT_APP_API_URL + configApp.logo?.data.attributes.url

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Container maxWidth="md" disableGutters>
        <Toolbar className={classes.toolbar}>
          <div>
            <ListItem onClick={handleClick} className="p-0">
              <ListItemAvatar>
                <Avatar alt={profile?.name} src={profile?.avatar} className={classes.avatar} />
              </ListItemAvatar>
              <ListItemText
                className="text-truncate d-none d-sm-block"
                primary={profile?.name}
                secondary={profile?.username}
              />
            </ListItem>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <List component="nav" className={classes.list}>
                {profile?.username && (
                  <>
                    <ListItem button onClick={() => setDialog('Account')}>
                      <ListItemAvatar>
                        <Avatar alt={profile?.name} src={profile?.avatar} />
                      </ListItemAvatar>
                      <ListItemText primary={profile?.name} secondary={profile?.username} />
                    </ListItem>
                    <Divider />
                  </>
                )}
                <ListItem button onClick={() => setDialog('Account')}>
                  <ListItemIcon>
                    <AccountCircleOutlined />
                  </ListItemIcon>
                  <ListItemText primary="ข้อมูลของฉัน" />
                </ListItem>
                <Divider />
                {profile?.username && profile.username === process.env.REACT_APP_ADMIN && (
                  <>
                    <ListItem button onClick={() => setDialog('User')}>
                      <ListItemIcon>
                        <AccessibilityNew />
                      </ListItemIcon>
                      <ListItemText primary="ผู้ใช้งานในระบบ" />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={() => setDialog('Notification')}>
                      <ListItemIcon>
                        <ChatOutlined />
                      </ListItemIcon>
                      <ListItemText primary="สร้างกิจกรรม" />
                    </ListItem>
                  </>
                )}
                <Divider />
                <ListItem button onClick={() => setDialog('News')}>
                  <ListItemIcon>
                    <Badge color="error" badgeContent={notification <= 0 ? 0 : notification}>
                      <NotificationsNoneOutlined />
                    </Badge>
                  </ListItemIcon>
                  <ListItemText primary="แจ้งเตือนกิจกรรม" />
                </ListItem>
                <Divider />
                <ListItem button onClick={handleAuth}>
                  <ListItemIcon>
                    <ExitToApp />
                  </ListItemIcon>
                  <ListItemText primary={profile?.username ? 'ออกจากระบบ' : 'เข้าสู่ระบบ'} />
                </ListItem>
              </List>
            </Popover>
          </div>

          {logo && <img className="img-fluid" src={logo} width="50" alt="logo" />}

          <IconButton onClick={() => setDialog('News')}>
            <Badge
              color="error"
              overlap="circular"
              badgeContent={notification <= 0 ? 0 : notification}
            >
              <NotificationsNoneOutlined className={classes.colorBlack} />
            </Badge>
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
