import { useEffect, useState } from 'react'
import { useStyles } from './styles'
import { db, storage } from '../../firebase'
import { Favorite, FavoriteBorder } from '@material-ui/icons'
import { useRecoilValue } from 'recoil'
import profileAtom from '../../recoil/profile'

export default function BlessHook() {
  const classes = useStyles()
  const profile = useRecoilValue(profileAtom)
  const [popup, setPopup] = useState(false)
  const [bless, setBless] = useState([])
  const [imageID, setImageID] = useState([])
  const [removeItem, setRemoveItem] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [lastVisible, setLastVisible] = useState()
  const [activeHeart, setActiveHeart] = useState()
  const [fullscreenDialog, setFullscreenDialog] = useState(false)
  const [IDComment, setIDComment] = useState('')

  useEffect(() => {
    getBless()
    return () => {
      getBless()
    }
  }, [])

  const handleComment = (imageID) => {
    setIDComment(imageID)
    setFullscreenDialog(true)
  }

  const handleClick = (event, item) => {
    setRemoveItem(item)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setPopup(false)
    setFullscreenDialog(false)
  }

  const handleOpenPopup = () => {
    setPopup(true)
    setAnchorEl(null)
  }

  const getBless = () => {
    db.collection(process.env.REACT_APP_PROJECT_ID)
      .doc('bless')
      .collection('data')
      .orderBy('dateCreate', 'desc')
      .limit(6)
      .onSnapshot((querySnapshot) => {
        const data = []
        const imageID = []
        querySnapshot.forEach((document) => {
          data.push(document.data())
          imageID.push(document.id)
        })
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]
        setLastVisible(lastVisible)
        setBless(data)
        setImageID(imageID)
      })
  }

  const nextPage = () => {
    db.collection(process.env.REACT_APP_PROJECT_ID)
      .doc('bless')
      .collection('data')
      .orderBy('dateCreate', 'desc')
      .startAfter(lastVisible)
      .limit(6)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((document) => {
          setBless((bless) => [...bless, document.data()])
          setImageID((imageID) => [...imageID, document.id])
        })
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]
        setLastVisible(lastVisible)
      })
  }

  const handleLike = (imageID) => {
    setActiveHeart(imageID)
    const docRef = db
      .collection(process.env.REACT_APP_PROJECT_ID)
      .doc('bless')
      .collection('data')
      .doc(imageID)
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          let peopleLike = doc.data().like
          const found = peopleLike.find((element) => element === profile.username)
          if (!found) {
            db.collection(process.env.REACT_APP_PROJECT_ID)
              .doc('bless')
              .collection('data')
              .doc(imageID)
              .set(
                {
                  like: [...doc.data().like, profile.username],
                },
                { merge: true },
              )
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onDelete = () => {
    db.collection(process.env.REACT_APP_PROJECT_ID)
      .doc('bless')
      .collection('data')
      .where('dateCreate', '==', removeItem.dateCreate)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((document) => {
          document.ref.delete()
          const desertRef = storage.ref(`images/${removeItem.imageName}`)
          desertRef
            .delete()
            .then(function () {
              handleClose()
            })
            .catch(function (error) {
              console.error(error)
              handleClose()
            })
        })
      })
  }

  const checkLike = (items, index) => {
    const found = items.find((element) => element === profile.username)
    if (found) {
      return (
        <Favorite
          className={[
            classes.like,
            'animate__animated',
            activeHeart === imageID[index] && 'animate__heartBeat',
          ].join(' ')}
        />
      )
    } else {
      return <FavoriteBorder />
    }
  }

  return {
    classes,
    profile,
    popup,
    bless,
    imageID,
    anchorEl,
    lastVisible,
    activeHeart,
    fullscreenDialog,
    IDComment,
    handleComment,
    handleClick,
    handleClose,
    handleOpenPopup,
    nextPage,
    handleLike,
    onDelete,
    checkLike,
  }
}
