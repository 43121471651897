import FullScreenDialog from '../../../components/Dialog'
import Notification from '../../../components/Notification'
import News from '../../../components/News'
import Account from '../../../components/Account'
import Calendar from '../../../components/Calendar'
import CeremonyAction from '../../../components/Schedule/CeremonyAction'
import { useRecoilState } from 'recoil'
import dialogAtom from '../../../recoil/dialog'
import { isEmpty, isArray } from 'lodash'
import User from '../../../components/User'

export default function Dialog({ handleClose, handleAuth }) {
  const [dialog, setDialog] = useRecoilState(dialogAtom)

  const renderDialog = (dialog) => {
    if (dialog === 'Notification') {
      return <Notification handleClose={handleClose} />
    }

    if (dialog === 'User') {
      return <User />
    }

    if (dialog === 'News') {
      return <News />
    }

    if (dialog === 'Account') {
      return <Account handleAuth={handleAuth} />
    }

    if (dialog === 'Calendar') {
      return <Calendar />
    }
  }

  const renderTitle = (dialog) => {
    if (dialog === 'Notification') {
      return 'สร้างกิจกรรม'
    }

    if (dialog === 'User') {
      return 'ผู้ใช้งานในระบบ'
    }

    if (dialog === 'News') {
      return 'แจ้งเตือนกิจกรรม'
    }

    if (dialog === 'Calendar') {
      return 'ปฏิทิน'
    }
  }

  return (
    <FullScreenDialog
      handleCloseDialog={() => setDialog(undefined)}
      open={!isEmpty(dialog)}
      title={renderTitle(dialog)}
    >
      {isArray(dialog) ? <CeremonyAction /> : renderDialog(dialog)}
    </FullScreenDialog>
  )
}
