import { db } from '../../firebase'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import _ from 'lodash'
import QRCode from 'qrcode.react'
import CloseIcon from '@material-ui/icons/Close'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import CircularProgress from '@material-ui/core/CircularProgress'
import copy from 'copy-to-clipboard'
import axios from 'axios'
import IconButton from '@material-ui/core/IconButton'
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined'
import Avatar from '@material-ui/core/Avatar'
import Alert from '@material-ui/lab/Alert'
import Collapse from '@material-ui/core/Collapse'
import Container from '@material-ui/core/Container'
import Backdrop from '@material-ui/core/Backdrop'
import { Fragment, useEffect, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(1, 1, 0),
  },
  list: {
    marginBottom: theme.spacing(1),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  qrcodeWrapper: {
    maxWidth: 100,
    maxHeight: 100,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  alert: {
    position: 'fixed',
    width: '100%',
    zIndex: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

export default function QRcode() {
  const classes = useStyles()
  const [qrcode, setQRcode] = useState()
  const [link, setLink] = useState([])
  const [alert, setAlert] = useState(false)

  useEffect(() => {
    const table = db
      .collection('table')
      .orderBy('tid')
      .onSnapshot(async (querySnapshot) => {
        const data = []
        querySnapshot.forEach((document) => {
          data.push(document.data())
        })
        setQRcode(data)
        getLink(data)
      })
    const getLink = async (data) => {
      for (const items of data) {
        const url = `${process.env.REACT_APP_URL}?table=${items.tname}`
        const contents = await shorten(url)
        setLink((link) => [...link, contents])
      }
    }
    return () => {
      table()
    }
  }, [])

  const downloadQR = (name) => {
    const canvas = document.getElementById(name)
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    let downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = `${name}.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const shorten = async (url) => {
    let data
    await axios.get(
      `https://api-ssl.bitly.com/v3/user/link_save?access_token=76ec7bd6e0426b35df76882a4385f39b88d45830&longUrl=${url}`,
    )
    await axios
      .get(
        `https://api-ssl.bitly.com/v3/link/lookup?url=${url}&access_token=76ec7bd6e0426b35df76882a4385f39b88d45830`,
      )
      .then((response) => {
        data = response.data.data.link_lookup[0].aggregate_link
      })
      .catch((error) => {
        console.error(error)
      })
    return data
  }

  const CopyToClipboard = (url) => {
    copy(url)
    setAlert(true)
    setTimeout(function () {
      setAlert(false)
    }, 3000)
  }

  return (
    <div>
      {_.isEmpty(qrcode) ? (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="md" disableGutters>
          <Collapse in={alert} className={classes.alert}>
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setAlert(false)}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              คัดลอกสำเร็จ
            </Alert>
          </Collapse>
          <Typography className={classes.text} variant="h5" gutterBottom>
            QRCode ({qrcode.length})
          </Typography>
          <List className={classes.list}>
            <Fragment>
              {qrcode.map((items, index) => {
                return (
                  <div key={index}>
                    {link[index] ? (
                      <ListItem>
                        <div className="pr-2">{index + 1}</div>
                        <ListItemAvatar>
                          <QRCode
                            id={`wedding${items.tid}`}
                            size={300}
                            level={'H'}
                            className={classes.qrcodeWrapper}
                            value={`${process.env.REACT_APP_URL}?table=${items.tname}`}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={items.tname + ` (${items.tnumber})`}
                          secondary={
                            <a href={link[index]} target="_blank" rel="noopener noreferrer">
                              Go to link
                            </a>
                          }
                          className="ml-3"
                        />
                        <IconButton onClick={() => CopyToClipboard(link[index])}>
                          <Avatar className={classes.avatar}>
                            <LinkOutlinedIcon />
                          </Avatar>
                        </IconButton>
                        <IconButton onClick={() => downloadQR(`wedding${items.tid}`)}>
                          <Avatar className={classes.avatar}>
                            <GetAppOutlinedIcon />
                          </Avatar>
                        </IconButton>
                      </ListItem>
                    ) : (
                      <div className="text-center py-5">
                        <CircularProgress color="inherit" size={20} />
                      </div>
                    )}
                  </div>
                )
              })}
            </Fragment>
          </List>
        </Container>
      )}
    </div>
  )
}
