import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  wrap: {
    paddingBottom: theme.spacing(1),
  },
  map: {
    width: '100%',
    height: 250,
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
  },
  media: {
    height: 200,
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  titleWrap: {
    padding: theme.spacing(2, 0, 2, 2),
  },
  title: {
    paddingLeft: theme.spacing(1),
  },
  cardWrapper: {
    marginTop: theme.spacing(2),
  },
  card: {
    margin: theme.spacing(0, 1, 2, 1),
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
  },
}))
