import React, { Component } from 'react'

export let longdo
export let map

export class LongdoMap extends Component {
  mapCallback = () => {
    longdo = window.longdo
    map = new window.longdo.Map({
      placeholder: document.getElementById(this.props.id),
      language: 'en',
    })
    map.location({ lon: this.props.lon, lat: this.props.lat }, true)
    map.zoom(15, true)
    var marker = new longdo.Marker({ lon: this.props.lon, lat: this.props.lat })
    map.Overlays.add(marker)
    map.Overlays.bounce(marker)
    map.Ui.DPad.visible(false)
    map.Ui.Zoombar.visible(false)
    map.Ui.Geolocation.visible(false)
    map.Ui.Toolbar.visible(false)
    map.Ui.Crosshair.visible(false)
    map.Ui.Scale.visible(false)
  }

  componentDidMount() {
    // const existingScript = document.getElementById('longdoMapScript')
    const callback = this.props.callback

    // if (!existingScript) {
    const script = document.createElement('script')
    script.src = `https://api.longdo.com/map/?key=${this.props.mapKey}`
    script.id = 'longdoMapScript'
    document.body.appendChild(script)

    script.onload = () => {
      this.mapCallback()
      if (callback) callback()
    }
    // }

    // if (existingScript) this.mapCallback();
    // if (existingScript && callback) callback();
  }

  render() {
    return <div id={this.props.id} style={{ width: '100%', height: '100%' }}></div>
  }
}
