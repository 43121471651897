import { useEffect, useState } from 'react'

export function useAddToHomescreenPrompt() {
  const [promptable, setPromptable] = useState(null)
  const [isInstalled, setIsInstalled] = useState(false)
  const [isVisible, setVisibleState] = useState(false)

  const hide = () => setVisibleState(false)

  const promptToInstall = () => {
    if (promptable) {
      return promptable.prompt()
    }
    return Promise.reject(
      new Error('Tried installing before browser sent "beforeinstallprompt" event'),
    )
  }

  useEffect(() => {
    if (promptable) {
      setVisibleState(true)
    }
  }, [promptable])

  useEffect(() => {
    const ready = (e) => {
      e.preventDefault()
      setPromptable(e)
    }

    const onInstall = () => {
      setIsInstalled(true)
    }

    window.addEventListener('beforeinstallprompt', ready)
    window.addEventListener('appinstalled', onInstall)

    return () => {
      window.removeEventListener('beforeinstallprompt', ready)
      window.removeEventListener('appinstalled', onInstall)
    }
  }, [])

  return { promptable, promptToInstall, isInstalled, isVisible, hide }
}
