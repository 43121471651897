import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  calendar: {
    padding: 0,
    height: 450,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  subheader: {
    backgroundColor: theme.palette.grey[100],
  },
}))
