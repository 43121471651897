import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: 'none',
  },
  paperWrap: {
    paddingTop: theme.spacing(2),
  },
  paper: {
    margin: theme.spacing(0, 2),
    padding: theme.spacing(3, 2, 3, 0),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  iconTable: {
    width: 50,
    height: 50,
    color: '#FFF',
  },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    padding: theme.spacing(2, 0, 0, 2),
  },
  container: {
    height: 'calc(100vh - 295px)',
  },
  searchWrap: {
    padding: theme.spacing(2),
  },
  search: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
  },
}))
