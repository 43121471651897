import { Avatar, Dialog, IconButton } from '@material-ui/core'
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined'
import { useStyles } from './styles'
import { useRecoilValue } from 'recoil'
import configAppAtom from '../../../../recoil/configApp'
import { useEffect, useState } from 'react'

export default function Popup() {
  const classes = useStyles()
  const read_card = localStorage.getItem(
    `read_card_by_project_id_${process.env.REACT_APP_PROJECT_ID}`,
  )
  const configApp = useRecoilValue(configAppAtom)
  const [open, setOpen] = useState(false)
  const src =
    configApp.card &&
    process.env.REACT_APP_API_URL + configApp.card?.data.attributes.formats.large.url

  useEffect(() => {
    if (src && !read_card) {
      setOpen(true)
    }
  }, [src])

  const onClose = () => {
    setOpen(false)
    localStorage.setItem(`read_card_by_project_id_${process.env.REACT_APP_PROJECT_ID}`, true)
  }

  return (
    <Dialog onClose={onClose} open={open} className={classes.popup}>
      <div className="d-flex flex-wrap align-items-center">
        <img
          style={{ maxHeight: 'calc(100vh - 64px)' }}
          className="img-fluid p-1"
          src={src}
          alt="card"
        />
        <IconButton onClick={onClose} className={classes.button}>
          <Avatar className={classes.icon}>
            <ArrowForwardIosOutlinedIcon />
          </Avatar>
        </IconButton>
      </div>
    </Dialog>
  )
}
