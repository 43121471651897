import { Card, CardActionArea, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useStyles } from './styles'
import { useRecoilValue } from 'recoil'
import configAppAtom from '../../../../recoil/configApp'

export default function Gallery() {
  const classes = useStyles()
  const configApp = useRecoilValue(configAppAtom)
  const background =
    configApp.gallery_cover &&
    process.env.REACT_APP_API_URL + configApp.gallery_cover?.data.attributes.url

  return (
    <div className={classes.gallery}>
      <Card className="h-100">
        <CardActionArea className="h-100">
          <Link to="./gallery">
            <div
              className={
                'w-100 h-100 animate__animated animate__pulse animate__infinite animate__slow'
              }
              style={{
                background: `center / cover no-repeat url(${background})`,
              }}
            >
              <div className="d-flex align-items-center justify-content-center w-100 h-100">
                <div className={['px-4 py-2 ml-3', classes.border].join(' ')}>
                  <Typography align="center" variant="h5">
                    <strong>GALLERY</strong>
                  </Typography>
                </div>
              </div>
            </div>
          </Link>
        </CardActionArea>
      </Card>
    </div>
  )
}
