import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import NotificationHook from './Notification.hook'

export default function Notification(props) {
  const { title, setTitle, addNews } = NotificationHook(props)

  return (
    <div className="ab-c-m w-100 px-4">
      <div className="d-flex flex-column align-items-center">
        <Container maxWidth="md" disableGutters>
          <Typography className="pb-4 px-1">รายละเอียด</Typography>
          <TextField
            fullWidth
            autoFocus
            id="noti"
            variant="outlined"
            rows="10"
            multiline
            label="กิจกรรม"
            name="noti"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            className="mt-4"
            onClick={addNews}
          >
            แจ้งเตือนกิจกรรม
          </Button>
        </Container>
      </div>
    </div>
  )
}
