import { Typography, Paper } from '@material-ui/core'
import Countdown from 'react-countdown-now'
import { useStyles } from './styles'
import { useRecoilValue } from 'recoil'
import configAppAtom from '../../recoil/configApp'

function Renderer(props) {
  const classes = useStyles()

  if (props.completed) {
    return ''
  } else {
    return (
      <Paper
        square
        elevation={0}
        className="d-flex justify-content-center align-items-center text-center"
      >
        <div className="px-2 my-4">
          <div className={classes.number}>
            <Typography variant="h6" align="center">
              {props.days}
            </Typography>
          </div>
          <div className={classes.unit}>
            <Typography variant="body2" align="center">
              <small>days</small>
            </Typography>
          </div>
        </div>
        <div className="px-2 my-4">
          <div className={classes.number}>
            <Typography variant="h6" align="center">
              {props.hours}
            </Typography>
          </div>
          <div className={classes.unit}>
            <Typography variant="body2" align="center">
              <small>Hours</small>
            </Typography>
          </div>
        </div>
        <div className="px-2 my-4">
          <div className={classes.number}>
            <Typography variant="h6" align="center">
              {props.minutes}
            </Typography>
          </div>
          <div className={classes.unit}>
            <Typography variant="body2" align="center">
              <small>Minutes</small>
            </Typography>
          </div>
        </div>
        <div className="px-2 my-4">
          <div className={classes.number}>
            <Typography variant="h6" align="center">
              {props.seconds}
            </Typography>
          </div>
          <div className={classes.unit}>
            <Typography variant="body2" align="center">
              <small>Seconds</small>
            </Typography>
          </div>
        </div>
      </Paper>
    )
  }
}

export default function Time() {
  const configApp = useRecoilValue(configAppAtom)
  const start_date = configApp?.start_date

  if (start_date) {
    return <Countdown date={new Date(start_date)} renderer={(e) => <Renderer {...e} />} />
  }

  return ''
}
