import { useState } from 'react'
import profileAtom from '../../recoil/profile'
import { useRecoilValue } from 'recoil'
import { db } from '../../firebase'
import moment from 'moment'

export default function NotificationHook({ handleClose }) {
  const profile = useRecoilValue(profileAtom)
  const [title, setTitle] = useState('')

  const addNews = async () => {
    if (title) {
      await db
        .collection(process.env.REACT_APP_PROJECT_ID)
        .doc('news')
        .collection('data')
        .doc(moment().unix().toString())
        .set({
          username: profile.username,
          time: moment().format(),
          dateCreate: moment().unix(),
          title: title,
        })
        .then((doc) => {
          handleClose()
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  return { title, setTitle, addNews }
}
