import { Box, Typography } from '@material-ui/core'

export default function Menu(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      className="pos-relative"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  )
}
