import { useEffect, useState } from 'react'
import { useStyles } from './styles'
import { useRecoilState, useRecoilValue } from 'recoil'
import notificationAtom from '../../recoil/notification'
import newsAtom from '../../recoil/news'
import profileAtom from '../../recoil/profile'
import { db } from '../../firebase'

export default function NewsHook() {
  const classes = useStyles()
  const read_noti = Number(
    localStorage.getItem(`read_noti_by_project_id_${process.env.REACT_APP_PROJECT_ID}`),
  )
  const [notification, setNotification] = useRecoilState(notificationAtom)
  const news = useRecoilValue(newsAtom)
  const profile = useRecoilValue(profileAtom)
  const [removeItem, setRemoveItem] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    localStorage.setItem(`read_noti_by_project_id_${process.env.REACT_APP_PROJECT_ID}`, notification + read_noti)
    setNotification(0)
  }, [])

  const handleClick = (event, item) => {
    setRemoveItem(item)
    setAnchorEl(event.currentTarget)
  }

  const onDelete = () => {
    setAnchorEl(null)
    db.collection(process.env.REACT_APP_PROJECT_ID)
      .doc('news')
      .collection('data')
      .where('dateCreate', '==', removeItem.dateCreate)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((document) => {
          document.ref.delete().catch((error) => {
            console.error(error)
          })
        })
      })
  }

  return {
    classes,
    news,
    profile,
    anchorEl,
    handleClick,
    setAnchorEl,
    onDelete,
  }
}
