import React from 'react'
import GetUser from '../../../../lib/utils/getUser'
import { Avatar, ListItemAvatar, ListItemText } from '@material-ui/core'

export default function UserPost({ username, comment }) {
  const { avatar, name } = GetUser(username)

  return (
    <>
      <ListItemAvatar>
        <Avatar src={avatar} />
      </ListItemAvatar>
      <ListItemText primary={name} secondary={comment} />
    </>
  )
}
