import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import moment from 'moment'
import 'moment/locale/th'
import { CircularProgress, Backdrop, Avatar } from '@material-ui/core'
import { db } from '../../firebase'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    border: '1px solid #FFF',
    borderRadius: '50%',
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
    },
  },
}))

export default function Users() {
  const classes = useStyles()
  const [user, setUser] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    getUsers()
    return () => {
      getUsers()
    }
  }, [])

  const getUsers = () => {
    setLoading(true)
    db.collection(process.env.REACT_APP_PROJECT_ID)
      .doc('users')
      .collection('data')
      .orderBy('dateUpdate', 'desc')
      .onSnapshot((querySnapshot) => {
        const data = []
        querySnapshot.forEach((document) => {
          data.push(document.data())
        })
        setUser(data)
        setLoading(false)
      })
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>No. ({user.length})</TableCell>
              <TableCell>รูปโปรไฟล์</TableCell>
              <TableCell>ชื่อ-นามสกุล</TableCell>
              <TableCell>อีเมล</TableCell>
              <TableCell>เข้าสู่ระบบ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {user?.map((items, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell>
                  <Avatar alt={items.name} src={items.avatar} className={classes.avatar} />
                </TableCell>
                <TableCell>{items.name}</TableCell>
                <TableCell>{items.username}</TableCell>
                <TableCell>{moment(items.dateUpdate).format('LLL')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
