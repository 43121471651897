import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  galleryWrap: {
    paddingTop: 4,
  },
  gridList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  buttonWrap: {
    padding: theme.spacing(2, 1, 3, 1),
  },
}))
