import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  popup: {
    zIndex: '1500 !important',
  },
  button: {
    position: 'absolute',
    right: 0,
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
  },
}))
