import { useEffect, useState } from 'react'
import { findUser } from '../../../services/User/findUser'

export default function GetUser(username) {
  const [avatar, setAvatar] = useState('')
  const [name, setName] = useState('')

  useEffect(() => {
    if (username) {
      findUser({ username })
        .then(({ data }) => {
          const avatar = data.data[0].attributes.avatar
          const name = data.data[0].attributes.name
          setAvatar(avatar)
          setName(name)
        })
        .catch((err) => {
          console.log('err :>> ', err)
        })
    }
  }, [])

  return { avatar, name }
}
