import Map from '../../components/Map'
import Divider from '@material-ui/core/Divider'
import MapIcon from '@material-ui/icons/Map'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import { useStyles } from './styles'
import { useRecoilValue } from 'recoil'
import configAppAtom from '../../recoil/configApp'
import { Button, Grid } from '@material-ui/core'
import { BusinessOutlined } from '@material-ui/icons'

export default function Maps() {
  const classes = useStyles()
  const configApp = useRecoilValue(configAppAtom)

  return (
    <>
      {configApp.map?.map((item, index) => (
        <div key={index} className={classes.wrap}>
          <Grid container alignItems="center" className={classes.titleWrap}>
            <Avatar className={classes.icon}>
              <BusinessOutlined fontSize="small" />
            </Avatar>
            <Grid className={classes.title}>
              <Typography variant="h6">{item.title}</Typography>
              <Typography color="textSecondary" variant="body2">
                {item.description}
              </Typography>
            </Grid>
          </Grid>
          <div className={classes.map}>
            <Map lat={item.lat} long={item.long} id={index} />
          </div>
          <div className={classes.cardWrapper}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h6" component="p">
                  Google Map{' '}
                  <Typography variant="body2" component="span" color="textSecondary">
                    ({item.title})
                  </Typography>
                </Typography>
                <List className={classes.list}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className={classes.icon}>
                        <MapIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary="กดที่นี่ สำหรับนำทางไปยัง Google Map เพื่อง่ายและสะดวกต่อการเดินทางมาของคุณ" />
                  </ListItem>
                </List>
                <Divider light className="my-3" />
                <Typography align="center">
                  <a
                    href={`https://goo.gl/maps/${item.google_link}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button color="primary" variant="contained">
                      นำทางโดย Google Map
                    </Button>
                  </a>
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      ))}
    </>
  )
}
