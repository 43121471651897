import axios from 'axios'

export const getApp = async () => {
  const config = {
    headers: {
      Authorization: 'Bearer ' + process.env.REACT_APP_API_TOKEN,
    },
  }

  return await axios.get(
    `${process.env.REACT_APP_API_URL}/api/weddings/${process.env.REACT_APP_PROJECT_ID}?populate=deep`,
    config,
  )
}
