import './style.scss'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Avatar from '@material-ui/core/Avatar'
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined'
import { Typography, Grid, Divider } from '@material-ui/core'
import { useState } from 'react'
import { useStyles } from './styles'
import { useRecoilValue } from 'recoil'
import configAppAtom from '../../recoil/configApp'

const localizer = momentLocalizer(moment)

export default function MyCalendar() {
  const classes = useStyles()
  const configApp = useRecoilValue(configAppAtom)
  const [month, setMonth] = useState(moment().format('MM'))

  return (
    <div>
      <Calendar
        events={{
          title: 'Wedding',
          start: new Date(configApp?.start_date),
          end: new Date(configApp?.end_date),
        }}
        defaultDate={moment().toDate()}
        localizer={localizer}
        className={classes.calendar}
        views={['month']}
        onNavigate={(date) => {
          setMonth(moment(date).format('MM'))
        }}
      />
      <List className={classes.list}>
        <ListSubheader className={classes.subheader}>นัดหมาย</ListSubheader>
        {month === moment(configApp?.start_date).format('MM') ? (
          <ListItem>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <EventAvailableOutlinedIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Wedding"
              secondary={`${moment(configApp?.start_date).format(
                'วันddddที่ D MMMM YYYY',
              )} เวลา ${moment(configApp?.start_date).format('HH:mm')} - ${moment(
                configApp.end_date,
              ).format('HH:mm')} น.`}
            />
          </ListItem>
        ) : (
          <Typography
            color="textSecondary"
            variant="body2"
            className="py-3"
            gutterBottom
            component="div"
          >
            <Grid container justifyContent="center">
              <EventAvailableOutlinedIcon />
              <div className="pl-2">ไม่พบนัดหมาย</div>
            </Grid>
          </Typography>
        )}
        <Divider light />
      </List>
    </div>
  )
}
