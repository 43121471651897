import { Typography } from '@material-ui/core'
import GetVersion from '../../../../lib/utils/getVersion'

export default function Copyright() {
  const { version } = GetVersion()

  return (
    <>
      <Typography variant="body2" color="textSecondary" align="center">
        {`Copyright © Chairat Akkaramethachote ${new Date().getFullYear()}.`}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        version {version}
      </Typography>
    </>
  )
}
