import { Avatar, ListItemAvatar, ListItemText } from '@material-ui/core'
import GetUser from '../../../../lib/utils/getUser'
import moment from 'moment'

export default function Users({ username, dateShow }) {
  const { avatar, name } = GetUser(username)

  return (
    <>
      <ListItemAvatar>
        <Avatar src={avatar}>{name?.charAt(0).toUpperCase()}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={name} secondary={moment(dateShow).from()} />
    </>
  )
}
