import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    width: '100%',
    padding: theme.spacing(0, 2),
    marginBottom: -40,
    zIndex: 2,
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
  },
}))
