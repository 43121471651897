import axios from 'axios'

export const getUser = async () => {
  const config = {
    headers: {
      Authorization: 'Bearer ' + process.env.REACT_APP_API_TOKEN,
    },
  }

  return await axios.get(`${process.env.REACT_APP_API_URL}/api/wedding-users`, config)
}
