import { Paper, Typography } from '@material-ui/core'
import { useRecoilValue } from 'recoil'
import configAppAtom from '../../../recoil/configApp'
import { useStyles } from './styles'

export default function Meaning() {
  const classes = useStyles()
  const configApp = useRecoilValue(configAppAtom)

  return (
    <Paper square elevation={0} className={classes.root}>
      <div className="pb-1">
        <Typography variant="h5" gutterBottom>
          ความหมายในพิธีสมรสศักดิ์สิทธิ์
        </Typography>
      </div>
      {configApp.meaning?.map((item, index) => (
        <div className={classes.paragraph}>
          <Typography variant="h6" gutterBottom>
            {item.title}
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            {item.description}
          </Typography>
        </div>
      ))}
    </Paper>
  )
}
