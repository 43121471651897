import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  paragraph: {
    padding: theme.spacing(1, 2),
  },
}))
