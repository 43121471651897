import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  description: {
    'div > p': { padding: 0 },
  },
}))
