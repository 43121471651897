import { Avatar, ListItemAvatar } from '@material-ui/core'
import React from 'react'
import GetUser from '../../../../lib/utils/getUser'

export default function UserPost({ username }) {
  const { avatar } = GetUser(username)

  return (
    <ListItemAvatar>
      <Avatar alt="Profile Picture" src={avatar} />
    </ListItemAvatar>
  )
}
