import './style.scss'
import Typography from '@material-ui/core/Typography'
import { Grid, Divider } from '@material-ui/core'
import PaymentHook from './Payment.hook'

export default function Payment() {
  const { bank } = PaymentHook()

  return (
    <>
      <Grid container className="px-3 py-4">
        <Grid item xs={12} className="pt-3">
          <h5>
            <i className="far fa-credit-card pr-2" />
            กล่องรับซองด้วยบัญชีธนาคาร
          </h5>
        </Grid>
        <Grid item xs={12} className="py-4">
          <Divider />
        </Grid>
        <Grid item xs={12} className="pb-4">
          <Typography>
            ขอขอบคุณทุกท่านที่ให้เกียรติมาเป็นส่วนหนึ่งในงานครั้งนี้
            ขาดตกบกพร่องสิ่งใดไปต้องขออภัยมา ณ ที่นี้ด้วยครับ
          </Typography>
        </Grid>
        {bank?.map((item, index) => {
          const accountBank = item.account_bank
          const logoBnak =
            process.env.REACT_APP_API_URL + item.image.data.attributes.formats.thumbnail.url
          const accountName = item.account_name
          const accountNumber = item.account_number
          const accountBranch = item.account_branch

          return (
            <>
              <Grid item xs={12} className="pb-2 pl-2">
                <Typography>
                  ธนาคาร : <strong>{accountBank}</strong>
                </Typography>
              </Grid>
              <Grid key={index} container item xs={12} alignItems="center">
                <img className="img-fluid b-r-10" src={logoBnak} width="80" alt="logo_bank" />
                <div className="pl-5">
                  <Typography gutterBottom>ชื่อบัญชี : {accountName}</Typography>
                  <Typography gutterBottom>
                    เลขที่บัญชี : <strong>{accountNumber}</strong>
                  </Typography>
                  <Typography>สาขา : {accountBranch}</Typography>
                </div>
              </Grid>
              {bank.length - index !== 1 && (
                <Grid item xs={12} className="my-3 ml-2">
                  <Divider />
                </Grid>
              )}
            </>
          )
        })}
      </Grid>
    </>
  )
}
