import {
  CssBaseline,
  Container,
  AppBar,
  Avatar,
  Typography,
  Tabs,
  Tab,
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import {
  RoomOutlined,
  ScheduleOutlined,
  HomeOutlined,
  Instagram,
  WeekendOutlined,
} from '@material-ui/icons'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Gallery from '../pages/Gallery'
import Tables from '../pages/Tables'
import Maps from '../pages/Maps'
import Schedule from '../pages/Schedule'
import Home from '../pages/Home'
import Bless from '../pages/Bless'
import LayoutHook from './Layout.hook'
import Nav from './components/Nav'
import Dialog from './components/Dialog'

export default function Layout(props) {
  const hook = LayoutHook(props)
  const {
    classes,
    id,
    open,
    anchorEl,
    value,
    logoutDialog,
    handleClick,
    handleClose,
    handleAuth,
    handleChange,
    onLogout,
  } = hook

  return (
    <>
      <Nav
        id={id}
        open={open}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        handleAuth={handleAuth}
      />
      <CssBaseline />
      <Container maxWidth="md" disableGutters>
        <div className={classes.container}>
          {value === 0 &&
            (props.match.path === '/' ? (
              <Home handleChange={handleChange} />
            ) : (
              <Gallery handleChange={handleChange} />
            ))}
          {value === 1 && <Schedule />}
          {value === 2 && <Tables />}
          {value === 3 && <Maps />}
          {value === 4 && <Bless />}
        </div>
      </Container>
      <AppBar color="inherit" position="fixed" className={classes.tabsMenu}>
        <Container maxWidth="md" disableGutters>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab icon={<HomeOutlined />} page="home" className={classes.tabsize} label="หน้าแรก" />
            <Tab
              icon={<ScheduleOutlined />}
              page="schedule"
              className={classes.tabsize}
              label="พิธีการ"
            />
            <Tab
              icon={<WeekendOutlined />}
              page="tables"
              className={classes.tabsize}
              label="ที่นั่ง"
            />
            <Tab icon={<RoomOutlined />} page="maps" className={classes.tabsize} label="แผนที่" />
            <Tab icon={<Instagram />} page="bless" className={classes.tabsize} label="อวยพร" />
          </Tabs>
        </Container>
      </AppBar>

      <MuiDialog
        open={logoutDialog}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.paper}>
          <Avatar className={classes.logout}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant="h5">Sign Out</Typography>
        </div>
        <DialogContent>
          <DialogContentText className="text-center" id="alert-dialog-description">
            คุณต้องการลงชื่อออกจากระบบใช่หรือไม่
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ยกเลิก</Button>
          <Button onClick={onLogout} autoFocus>
            ออกจากระบบ
          </Button>
        </DialogActions>
      </MuiDialog>

      <Dialog handleClose={handleClose} handleAuth={handleAuth} />
    </>
  )
}
