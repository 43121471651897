import React, { useCallback, useState } from 'react'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined'
import { db, storage } from '../../firebase'
import { useDropzone } from 'react-dropzone'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import * as loadImage from 'blueimp-load-image'
import 'react-image-crop/dist/ReactCrop.css'
import Alert from '@material-ui/lab/Alert'
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined'
import Collapse from '@material-ui/core/Collapse'
import CloseIcon from '@material-ui/icons/Close'
import _, { isEmpty } from 'lodash'
import Avatar from '@material-ui/core/Avatar'
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import Button from '@material-ui/core/Button'
import { useStyles } from './styles'
import { useRecoilValue } from 'recoil'
import profileAtom from '../../recoil/profile'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

export default function FormUpload() {
  const classes = useStyles()
  const profile = useRecoilValue(profileAtom)
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState(false)
  // const [progress, setProgress] = useState(false);
  const [loading, setLoading] = useState(false)
  const [upload, setUpload] = useState(false)
  const [bless, setBless] = useState(false)
  const [alert, setAlert] = useState(false)

  const handleClick = () => {
    if (!isEmpty(profile)) {
      setOpen(true)
    } else {
      window.location.href = '/login'
    }
  }

  const handleClose = () => {
    setOpen(false)
    setFile(false)
    // setProgress(false)
    setLoading(false)
    setBless(false)
    setAlert(false)
  }

  const onDrop = useCallback((acceptedFiles) => {
    setUpload(true)
    loadImage(acceptedFiles[0])
      .then(function (data) {
        let aspectRatio = data.originalHeight > data.originalWidth ? 5 / 4 : 16 / 9
        Preview(acceptedFiles[0], aspectRatio)
      })
      .catch(function (err) {
        console.log(err)
      })
  }, [])

  const Preview = (file, aspectRatio) => {
    loadImage(
      file,
      function (img, data) {
        if (data.imageHead) {
          img.toBlob(
            function (blob) {
              loadImage.replaceHead(blob, data.imageHead, function (newBlob) {
                setFile(URL.createObjectURL(newBlob))
                setUpload(false)
              })
            },
            'image/jpeg',
            0.3,
          )
        } else {
          setFile(URL.createObjectURL(file))
          setUpload(false)
        }
      },
      { meta: true, canvas: true, orientation: 1, aspectRatio: aspectRatio },
    )
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    multiple: false,
  })

  const handleSubmit = () => {
    if (!_.isEmpty(file)) {
      setLoading(true)
      UploadImage(file)
    } else {
      setAlert(true)
    }
  }

  const UploadImage = (croppedImage) => {
    var getFileBlob = function (url, cb) {
      var xhr = new XMLHttpRequest()
      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.addEventListener('load', function () {
        cb(xhr.response)
      })
      xhr.send()
    }

    getFileBlob(croppedImage, (blob) => {
      const imageName = moment().unix()
      const uploadTask = storage
        .ref(`${process.env.REACT_APP_PROJECT_ID}/images/${imageName}`)
        .put(blob)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
          // setProgress(progress)
        },
        function (error) {
          console.log(`error` + error)
        },
        function () {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              addBless(downloadURL, imageName, profile, bless)
            })
            .catch((error) => {
              console.error(error)
            })
        },
      )
    })
  }

  const addBless = (imageUrl, imageName, profile, bless) => {
    db.collection(process.env.REACT_APP_PROJECT_ID)
      .doc('bless')
      .collection('data')
      .doc(moment().unix().toString())
      .set(
        {
          dateCreate: moment().unix(),
          dateShow: moment().format(),
          imageName: imageName,
          imageUrl: imageUrl,
          username: profile.username,
          bless: bless || '',
          comment: [],
          like: [],
        },
        { merge: false },
      )
      .then((doc) => {
        handleClose()
        window.scrollTo(0, 0)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <div>
      <IconButton className={[classes.button, 'ab-c-b'].join(' ')} onClick={handleClick}>
        <Avatar className={[classes.avatar, 'animate__animated animate__pulse animate__infinite']}>
          <RateReviewOutlinedIcon />
        </Avatar>
      </IconButton>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar position="fixed" className={classes.appBar}>
          <Container maxWidth="md" disableGutters>
            <Toolbar className={classes.toolbar}>
              <IconButton edge="start" color="inherit" onClick={handleClose}>
                <ArrowBackOutlinedIcon fontSize="large" />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                เขียนคำอวรพร
              </Typography>
              {loading ? (
                <CircularProgress color="inherit" size={20} className="mr-4" />
              ) : (
                <Button size="large" className={classes.share} onClick={handleSubmit}>
                  แชร์
                </Button>
              )}
            </Toolbar>
          </Container>
        </AppBar>
        <DialogContent className={classes.fileuploadWrapper}>
          <Container maxWidth="md" disableGutters>
            <Typography color="textSecondary" className="py-2" component="div">
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid container item xs>
                  <StarBorderOutlinedIcon />
                  <div className="pl-2">Blessing</div>
                </Grid>
                {file && (
                  <Grid item>
                    <IconButton onClick={() => setFile(false)} size="small">
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Typography>
            <Collapse in={alert} className="mb-2">
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => setAlert(false)}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                อัพโหลดรูปภาพ และเขียนคำอวยพรของคุณ
              </Alert>
            </Collapse>
            {file ? (
              <Grid container className={classes.uploadBox}>
                <Grid item xs={12}>
                  <img className={classes.imageUpload} src={file} alt="" />
                </Grid>
              </Grid>
            ) : (
              <div {...getRootProps({ className: classes.dropbox })}>
                <input {...getInputProps()} />
                {upload ? (
                  <Typography align="center" component="div">
                    <CircularProgress size={50} />
                    <p className="pt-3">กำลังอัพโหลด...</p>
                  </Typography>
                ) : (
                  <Typography align="center" component="div">
                    <PhotoCameraOutlinedIcon style={{ fontSize: 50 }} />
                    <p className="pt-3">อัพโหลดรูปภาพของคุณ</p>
                  </Typography>
                )}
              </div>
            )}
            <TextField
              multiline
              rows="8"
              margin="dense"
              id="write"
              label="เขียนคำอวยพรของคุณที่นี่"
              type="text"
              fullWidth
              variant="outlined"
              value={bless ? bless : ''}
              onChange={(e) => setBless(e.target.value)}
            />
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  )
}
