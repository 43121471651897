import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  colorBlack: {
    color: theme.palette.common.black,
  },
  appBar: {
    boxShadow: 'none',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 56,
    paddingRight: theme.spacing(1),
  },
  container: {
    marginTop: 56,
    marginBottom: 51,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  list: {
    width: '100%',
    minWidth: 220,
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  tabsMenu: {
    top: 'auto',
    bottom: 0,
    zIndex: 1500,
  },
  tabsize: {
    fontSize: 10,
    minHeight: 50,
    padding: 7,
    textTransform: 'none',
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    border: '1px solid #FFF',
    borderRadius: '50%',
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
    },
  },
  logout: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}))
