import { useEffect, useState } from 'react'
import { useStyles } from './styles'
import { db } from '../firebase'
import { useSetRecoilState, useRecoilState } from 'recoil'
import configAppAtom from '../recoil/configApp'
import notificationAtom from '../recoil/notification'
import { getApp } from '../services/Config/getApp'
import profileAtom from '../recoil/profile'
import dialogAtom from '../recoil/dialog'
import { findUser } from '../services/User/findUser'
import newsAtom from '../recoil/news'

export default function LayoutHook({ match, history }) {
  const pos =
    match.path === '/' || match.path === '/gallery'
      ? 0
      : match.path === '/schedule'
      ? 1
      : match.path === '/tables'
      ? 2
      : match.path === '/maps'
      ? 3
      : match.path === '/bless'
      ? 4
      : match.path === '/' && false
  const classes = useStyles()
  const username = localStorage.getItem('username')
  const read_noti = Number(
    localStorage.getItem(`read_noti_by_project_id_${process.env.REACT_APP_PROJECT_ID}`),
  )
  const setConfigApp = useSetRecoilState(configAppAtom)
  const [profile, setProfile] = useRecoilState(profileAtom)
  const setDialog = useSetRecoilState(dialogAtom)
  const setNotification = useSetRecoilState(notificationAtom)
  const setNews = useSetRecoilState(newsAtom)
  const [value, setValue] = useState(pos)
  const [anchorEl, setAnchorEl] = useState(null)
  const [logoutDialog, setLogoutDialog] = useState(false)

  useEffect(() => {
    if (username) {
      findUser({ username })
        .then(({ data }) => {
          const id = data.data[0].id
          setProfile({ ...data.data[0].attributes, id })
        })
        .catch((err) => {
          console.log('err :>> ', err)
          localStorage.clear()
        })
    }
  }, [username])

  useEffect(() => {
    window.scrollTo(0, 0)
    const getNews = () => {
      db.collection(process.env.REACT_APP_PROJECT_ID)
        .doc('news')
        .collection('data')
        .orderBy('dateCreate', 'desc')
        .onSnapshot((querySnapshot) => {
          const data = []
          querySnapshot.forEach((document) => {
            data.push(document.data())
          })
          setNotification(data.length - read_noti)
          setNews(data)
        })
    }
    getNews()
    return () => {
      getNews()
    }
  }, [read_noti])

  useEffect(() => {
    getApp().then((res) => {
      setConfigApp(res.data.data?.attributes || {})
    })
  }, [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setLogoutDialog(false)
    setDialog(undefined)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleChange = (event, newValue) => {
    const page =
      newValue === 0
        ? '/'
        : newValue === 1
        ? '/schedule'
        : newValue === 2
        ? '/tables'
        : newValue === 3
        ? '/maps'
        : newValue === 4 && '/bless'
    history.push(page)
    setValue(newValue)
    handleClose()
    window.scrollTo(0, 0)
  }

  const handleAuth = () => {
    if (profile?.username) {
      setLogoutDialog(true)
      setAnchorEl(null)
    } else {
      history.push('/login')
    }
  }

  const onLogout = () => {
    setProfile({})
    localStorage.clear()
    setLogoutDialog(false)
    setAnchorEl(null)
  }

  return {
    classes,
    id,
    open,
    anchorEl,
    value,
    logoutDialog,
    handleClick,
    handleClose,
    handleAuth,
    handleChange,
    onLogout,
  }
}
