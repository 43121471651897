import './style.scss'
import { LoadingBless } from '../../components/Loading'
import FormUpload from '../../components/FormUpload'
import InfiniteScroll from 'react-infinite-scroll-component'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import FullScreenDialog from '../../components/Dialog'
import Comment from '../../components/Comment'
import {
  Avatar,
  ListItem,
  List,
  Divider,
  ListItemSecondaryAction,
  CircularProgress,
  IconButton,
  Button,
  Menu,
  Typography,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from '@material-ui/core'
import BlessHook from './Bless.hook'
import { Fragment } from 'react'
import Users from './components/Users'
import { isEmpty } from 'lodash'

export default function Bless() {
  const {
    classes,
    profile,
    popup,
    bless,
    imageID,
    anchorEl,
    lastVisible,
    activeHeart,
    fullscreenDialog,
    IDComment,
    handleComment,
    handleClick,
    handleClose,
    handleOpenPopup,
    nextPage,
    handleLike,
    onDelete,
    checkLike,
  } = BlessHook()

  return (
    <div>
      {isEmpty(bless) ? (
        <div className="pt-4">
          <LoadingBless />
        </div>
      ) : (
        <div>
          <InfiniteScroll
            dataLength={bless.length}
            next={nextPage}
            hasMore={true}
            loader={
              isEmpty(lastVisible) && (
                <h3 className="text-center mb-5">
                  <CircularProgress color="inherit" />
                </h3>
              )
            }
          >
            <List className={classes.list}>
              {bless.map((items, index) => (
                <Fragment key={index}>
                  <ListItem>
                    <Users {...items} />
                    {profile && profile?.username === items.username && (
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={(e) => handleClick(e, items)}
                        >
                          <MoreVertOutlinedIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                  <div className="px-4 pb-3">{items.bless}</div>
                  <div
                    className="text-center d-flex justify-content-center align-items-center"
                    onDoubleClick={(e) => handleLike(imageID[index])}
                  >
                    <div
                      className={[
                        classes.activeLike,
                        activeHeart === imageID[index] ? 'showHeart' : '',
                      ].join(' ')}
                    >
                      <FavoriteIcon className={classes.activeLikeSize} />
                    </div>
                    <img className="img-fluid" src={items.imageUrl} alt={items.imageUrl} />
                  </div>
                  <div className="mb-3">
                    {Array.isArray(items.like) && Array.isArray(items.comment) && (
                      <>
                        <Grid container>
                          <Grid container item alignItems="center" justifyContent="center" xs={6}>
                            <Button
                              fullWidth
                              size="large"
                              className={classes.btn}
                              onClick={(e) => handleLike(imageID[index])}
                            >
                              {checkLike(items.like, index)}
                              {items.like?.length !== 0 ? (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="span"
                                  className="pl-3"
                                >
                                  <strong>{items.like?.length}</strong>
                                </Typography>
                              ) : (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="span"
                                  className="pl-3"
                                >
                                  ถูกใจ
                                </Typography>
                              )}
                            </Button>
                          </Grid>
                          <Grid container item alignItems="center" justifyContent="center" xs={6}>
                            <Button
                              fullWidth
                              size="large"
                              className={classes.btn}
                              onClick={(e) => handleComment(imageID[index])}
                            >
                              <ChatBubbleIcon color="primary" />
                              {items.comment?.length !== 0 ? (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="span"
                                  className="pl-3"
                                >
                                  ความคิดเห็น {items.comment?.length} รายการ
                                </Typography>
                              ) : (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="span"
                                  className="pl-3"
                                >
                                  แสดงความคิดเห็น
                                </Typography>
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                        <Divider />
                      </>
                    )}
                  </div>
                </Fragment>
              ))}
            </List>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleOpenPopup}>
                <DeleteOutlineOutlinedIcon className="mr-3" />
                ลบรายการนี้
              </MenuItem>
            </Menu>
          </InfiniteScroll>
        </div>
      )}

      <FormUpload />

      <Dialog
        open={popup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        fullWidth
        maxWidth="sm"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.paper}>
          <Avatar className={classes.delete}>
            <DeleteOutlineOutlinedIcon />
          </Avatar>
          <Typography variant="h5">Delete</Typography>
        </div>
        <DialogContent>
          <DialogContentText className="text-center" id="alert-dialog-description">
            คุณต้องการลบรูปภาพนี้ใช่หรือไม่
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ยกเลิก</Button>
          <Button onClick={onDelete} autoFocus>
            ยืนยัน
          </Button>
        </DialogActions>
      </Dialog>

      <FullScreenDialog handleCloseDialog={handleClose} open={fullscreenDialog} title="ความคิดเห็น">
        <Comment IDComment={IDComment} />
      </FullScreenDialog>
    </div>
  )
}
