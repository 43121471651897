import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  number: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 60,
    height: 50,
    color: theme.palette.common.black,
    background: theme.palette.primary.light,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  unit: {
    color: theme.palette.common.black,
    background: theme.palette.primary.main,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    paddingBottom: 2,
  },
}))
