import { useState } from 'react'
import { useStyles } from './styles'
import copy from 'copy-to-clipboard'
import { useRecoilState, useRecoilValue } from 'recoil'
import profileAtom from '../../recoil/profile'
import { updateUser } from '../../services/User/updateUser'
import configAppAtom from '../../recoil/configApp'

export default function AccountHook() {
  const classes = useStyles()
  const configApp = useRecoilValue(configAppAtom)
  const [profile, setProfile] = useRecoilState(profileAtom)
  const [alert, setAlert] = useState(false)
  const [change, setChange] = useState(false)
  const [value, setValue] = useState('')
  const [fullscreenDialog, setFullscreenDialog] = useState(false)
  const logo = configApp.logo && process.env.REACT_APP_API_URL + configApp.logo?.data.attributes.url

  const handleClose = () => {
    setFullscreenDialog(false)
  }

  const CopyToClipboard = (url) => {
    copy(url)
    setAlert(true)
    setTimeout(function () {
      setAlert(false)
    }, 3000)
  }

  const handlePayment = () => {
    setFullscreenDialog(true)
  }

  const ChangeName = () => {
    window.scrollTo(0, 0)
    setChange(true)
    setValue(profile.name)
  }

  const handleChangeName = () => {
    if (value !== '') {
      const data = {
        name: value,
      }
      updateUser(profile.id, data).then(() => {
        setProfile({ ...profile, ...data })
      })
    }
    setChange(false)
  }

  return {
    logo,
    classes,
    alert,
    setAlert,
    change,
    value,
    setValue,
    fullscreenDialog,
    profile,
    configApp,
    handleClose,
    CopyToClipboard,
    handlePayment,
    ChangeName,
    handleChangeName,
  }
}
