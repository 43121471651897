import { useRecoilValue } from 'recoil'
import configAppAtom from '../../../../recoil/configApp'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'

export default function Youtube() {
  const configApp = useRecoilValue(configAppAtom)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (configApp?.end_date) {
      const specialTo = moment().diff(configApp?.end_date) > 0
      setOpen(specialTo)
    }
  }, [configApp])

  return open ? (
    <div className="p-b-24">
      {configApp && (
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            title="Wedding Presentation"
            className="embed-responsive-item"
            src={`https://www.youtube.com/embed/${configApp?.youtube_presentation}?rel=0&controls=0&fs=0`}
            allowFullScreen
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}
