import { useRecoilValue } from 'recoil'
import configAppAtom from '../../../../recoil/configApp'

export default function HeaderHook() {
  const configApp = useRecoilValue(configAppAtom)
  const title = configApp.title?.split('')
  const hashtag = configApp.hashtag

  return { title, hashtag }
}
