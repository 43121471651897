import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  themeWrapper: {
    width: '100%',
    padding: theme.spacing(0, 1, 3, 1),
    zIndex: 2,
  },
  avatar: {
    color: theme.palette.common.black,
  },
  headTheme: {
    padding: theme.spacing(0, 1, 2, 1),
  },
}))
