import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  subheader: {
    marginTop: '-1px',
    backgroundColor: theme.palette.grey[100],
  },
  list: {
    padding: 0,
  },
}))
