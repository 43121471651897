import { useState } from 'react'
import Ceremony from '../../components/Schedule/Ceremony'
import Meaning from '../../components/Schedule/Meaning'
import Time from '../../components/Countdown'
import { useStyles } from './styles'
import { AppBar, Container, Tab, Tabs } from '@material-ui/core'
import Menu from './components/Menu'

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

export default function Schedule() {
  const classes = useStyles()
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    if (newValue === 1) window.scrollTo(0, 0)
  }

  return (
    <>
      <AppBar className={classes.appbar} position="fixed" color="default">
        <Container maxWidth="md" disableGutters>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Ceremony" {...a11yProps(0)} />
            <Tab label="Meaning" {...a11yProps(1)} />
          </Tabs>
        </Container>
      </AppBar>
      <Menu value={value} index={0} className={classes.container}>
        <Time />
        <Ceremony />
      </Menu>
      <Menu value={value} index={1} className={classes.container}>
        <Time />
        <Meaning />
      </Menu>
    </>
  )
}
