import LongdoMapHook from './Map.hook'
import { LongdoMap } from './longdoMap'

export default function Map({ lat, long, id }) {
  const { initMap } = LongdoMapHook()

  if (lat && long) {
    return (
      <LongdoMap
        id={`longdo-map${id}`}
        lat={lat}
        lon={long}
        mapKey="e83f07bf65fea0334580574a9fc5977f"
        callback={initMap}
      />
    )
  }

  return <></>
}
