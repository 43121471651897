import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 56,
    paddingRight: theme.spacing(1),
  },
  list: {
    width: '100%',
    minWidth: 220,
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    border: '1px solid #FFF',
    borderRadius: '50%',
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
    },
    cursor: 'pointer',
  },
  colorBlack: {
    color: theme.palette.common.black,
  },
}))
