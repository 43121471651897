import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  list: {
    marginBottom: theme.spacing(1),
  },
  delete: {
    margin: theme.spacing(1),
    backgroundColor: '#dc3545',
  },
  maxHeight: {
    maxHeight: 400,
  },
  like: {
    color: 'red',
  },
  activeLike: {
    position: 'absolute',
    width: '100%',
    opacity: 0,
  },
  activeLikeSize: {
    fontSize: '15vh',
    color: '#FFFFFFE6',
  },
  btn: {
    padding: '12px 0 10px 0',
    borderRadius: 0,
  },
}))
