import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  appbar: {
    marginTop: 56,
    boxShadow: theme.shadows[0],
    backgroundColor: theme.palette.common.white,
  },
  container: {
    marginTop: 105,
  },
}))
