import { ArrowForwardIosOutlined } from '@material-ui/icons'
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Paper,
} from '@material-ui/core'
import { Fragment } from 'react'
import { isEmpty } from 'lodash'
import CeremonyHook from './Ceremony.hook.js'

export default function Ceremony() {
  const { classes, ceremony, componentsIcon, setDialog } = CeremonyHook()

  return (
    <Paper square elevation={0}>
      <List className={classes.list}>
        {ceremony?.map(({ group_name, title, description, image, avatar, action }, index) => {
          const DynamicComponentIcon = componentsIcon[avatar]

          return (
            <Fragment key={index}>
              {!isEmpty(group_name) && (
                <ListSubheader className={classes.subheader}>{group_name}</ListSubheader>
              )}
              <ListItem
                button={!isEmpty(action)}
                onClick={!isEmpty(action) ? () => setDialog(action) : null}
              >
                <ListItemAvatar>
                  {avatar ? (
                    <Avatar className={classes.avatar}>
                      <DynamicComponentIcon />
                    </Avatar>
                  ) : (
                    <Avatar src={process.env.REACT_APP_API_URL + image?.data?.attributes.url} />
                  )}
                </ListItemAvatar>
                <ListItemText primary={title} secondary={description} />
                {!isEmpty(action) && <ArrowForwardIosOutlined />}
              </ListItem>
              <Divider light />
            </Fragment>
          )
        })}
      </List>
    </Paper>
  )
}
